
import { computed } from 'vue'
import { useMapGetters } from 'public/src/pages/store_pages/hooks/useVuex.js'

export const useGetChoiceLabelImg = () => {
  const { locals = {} } = useMapGetters(['locals'])
  const { appLanguage } = typeof window !== 'undefined' ? gbCommonInfo : locals.value
  const choiceLabelImg = computed(() => {
    switch (appLanguage) {
      case 'ja':
        return '//img.ltwebstatic.com/images3_ccc/2024/09/19/86/1726716361096fa709771cbdb0af2a12700341be48.png'
      default:
        return '//img.ltwebstatic.com/images3_ccc/2024/07/12/2e/1720753743224f917b7f46982c88d6e5ff7fdf8dc8.png'
    }
  })
  return {
    choiceLabelImg,
  }
}
