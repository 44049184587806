import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
// import { getLanguage } from 'public/src/services/api/index.js'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'

export default {
  data() {
    return {
      constantData: {},
      itemLanguage: {},
      goodsItemInfo: null,
    }
  },
  created () {
    const { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, GB_cssRight, WEB_CLIENT, LAZY_IMG_PIXEL, langPath, PUBLIC_CDN, LAZY_IMG_SOLID_COLOR = '', appLanguage  } = typeof window === 'undefined' ? this.context || {} : gbCommonInfo || {}
    const IS_RW = WEB_CLIENT !== 'shein'
    this.constantData = Object.assign({}, { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW, LAZY_IMG_PIXEL, langPath, PUBLIC_CDN, appLanguage })
  },
  methods: {
    async initProductList(params) {
      this.itemLanguage = this.context?.language?.itemLanguage || {}
      // if (!Object.keys(this.itemLanguage).length) {
      //   let language = {}
      //   if (typeof window !== 'undefined') {
      //     const result = await getLanguage('product_item')
      //     this.itemLanguage = language = result?.language || {}
      //   }
      //   this.goodsItemInfo = new GoodsItemInfo({
      //     itemLanguage: language,
      //     locals: {
      //       IS_RW: this?.context?.WEB_CLIENT !== 'shein',
      //       lang: this?.context?.lang,
      //       GB_cssRight: this?.context?.GB_cssRight,
      //       IMG_LINK: this?.context?.IMG_LINK,
      //     }
      //   })
      // }

      this.goodsItemInfo = new GoodsItemInfo({
        itemLanguage: this.itemLanguage,
        locals: {
          IS_RW: this?.context?.WEB_CLIENT !== 'shein',
          lang: this?.context?.lang,
          GB_cssRight: this?.context?.GB_cssRight,
          IMG_LINK: this?.context?.IMG_LINK,
        }
      })
      if (this.goodsItemInfo && params) {
        await this.getProductInfo(params)
      }
    },
    // 新版商品项预处理服务
    dealProductsPretreatInfo({ products, itemConfig, promotionInfoFromServer, promotionLanguage }) {
      this.itemLanguage = this.context?.language?.itemLanguage || {}
      return dealProductsPretreatInfo({
        products, 
        itemConfig,
        promotionInfoFromServer,
        language: promotionLanguage || {},
      })
    },
    /*
    ** products: 商品列表
    ** itemConfig: 商品配置
    ** field: 需要请求的字段
    ** pageKey: 页面key
    ** onlyDealData: 是否只处理数据，不请求(目前默认false, 需要特别注意，如果在created中调用，需要传true)
    ** promotionInfoFromServer: 服务端传过来的促销信息
    */
    async getProductInfo({ products = [], itemConfig = {}, field = {}, pageKey = 'campaigns', subPageKey = '', onlyDealData = false, promotionInfoFromServer = {}, mallInfoForSite = [] }) {
      // if (typeof window === 'undefined') return Promise.resolve()
      if(!products?.length) return Promise.resolve()
      const fields = getAtomicRequestFileds(itemConfig, ['prices'])
      await this.goodsItemInfo.getProductInfo({
        goods: products,
        requestFields: { ...fields, ...field },
        itemConfig,
        pageKey,
        subPageKey,
        onlyDealData,
        promotionInfoFromServer,
        mallInfoForSite,
      })
    },
  },
}
