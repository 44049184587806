<template>
  <StoreFollowBtnWrap
    v-tap="getAnalysisData('2-22-21', { item: innerShopData })"
    v-expose="getAnalysisData('2-22-20', { item: innerShopData }, 'brandCollect')"
    scene="StoreDetail"
    auto-login
    :follow-state="!!innerShopData.followsStatus"
    :store-id="innerShopData.storeCode"
    :language="storeBannerLang"
    :shop-data="innerShopData"
    :store-abt-result="storeAbtResult"
    :hasCccfollowFloat="hasCccfollowFloat"
    @state-changed="onSwitchFollow"
    @need-login="onLogin"
    @state-change-fail="onSwitchFollowFail"
  >
    <template #button>
      <slot
        name="button"
        :shop-data="innerShopData"
      ></slot>
    </template>
  </StoreFollowBtnWrap>
</template>

<script>
import mixins from '../../mixins/mixin'

export default {
  mixins: [mixins],
}
</script>

<script setup name="StoreFollowBtn">
import { reactive, computed } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import StoreFollowBtnWrap from 'public/src/pages/store_pages/components/StoreFollowBtnWrap.vue'
import { getStoreFollowsCount } from 'public/src/services/api/store'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps(commonProps)

const storeBannerLang = computed(() => props.context?.language?.storeBanner || {})
const storeAbtResult = computed(() => props.context?.storeAbtResult || {})
// 时尚店铺配置展示新策略关注条+命中 abt 中的 B
const hasCccfollowFloat = computed(() => props.sceneData?.storeDeliverInfo?.ccc?.followSteer)
const innerShopData = reactive(props.propData?.storeBanner || {})
const onSwitchFollow = (value) => {
  innerShopData.followsStatus = value
  getFollowCount()
  daEventCenter.triggerNotice({
    daId: '2-22-22',
    bindData: {
      item: innerShopData,
    },
  })
}

const getFollowCount = () => {
  const { storeCode } = innerShopData
  return getStoreFollowsCount({
    store_ids: [storeCode],
    cache: false,
    isNewLimit: storeAbtResult.value?.raiseupperlimit?.p?.raiseupperlimit === 'B',
  })
    .then(res => {
      const store_list = res?.info?.store_list
      if(!Array.isArray(store_list)) throw new Error('get follows count failed')

      const followsCount = store_list.find(item => item.store_id === storeCode)?.count
      innerShopData.followsCount = followsCount
      appEventCenter.$emit('updateStoreFollowsCount', {
        storeCode,
        followsCount
      })
    })
    .catch(() => {
      console.warn('updated follows count failed')
    })
}

const onSwitchFollowFail = (error) => {
  daEventCenter.triggerNotice({
    daId: '2-22-22',
    bindData: {
      item: {
        error,
        ...innerShopData,
        followsStatus: !innerShopData.followsStatus,
      }
    },
  })
}

const onLogin = () => {
  SHEIN_LOGIN.show({
    show: true,
    from: 'other',
    bi: 'coupon',
    ga: 'coupons',
    cb: (userData) => {
      window?._gb_app_?.$emit('init-userIndex-userData', userData)
    }
  })
}
</script>
