import ClientOnly from 'vue-client-only'
import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
// import StaticImage from '../components/static-image/index.vue'

const componentsMap = {
  // 店铺横向卡片列表
  'StoreHorizontalCardList': () => import(/* webpackChunkName: "cccx-store-card-list" */'../campaigns-components/cccx-store-card-list/cccx-store-card-list.vue'),
  // 店铺纵向卡片列表
  'StoreCardList': () => import(/* webpackChunkName: "cccx-store-card-list" */'../campaigns-components/cccx-store-card-list/cccx-store-card-list.vue'),
  // 多店铺横滑组件
  'StoreMultiSlide': () => import(/* webpackChunkName: "cccx-store-multi-slide" */'../campaigns-components/cccx-store-multi-slide/index.vue'),
  // 意见收集组件
  'Survey': () => import(/* webpackChunkName: "cccx-survey" */'../campaigns-components/cccx-survey/cccx-survey.vue'),
  // look book 组件
  'LookBook': () => import(/* webpackChunkName: "ccc-look-book" */'../campaigns-components/cccx-look-book/cccx-look-book.vue'),
  // 店铺单卡片组件
  'StoreSingleCard': () => import(/* webpackChunkName: "cccx-store-single-card" */'../campaigns-components/cccx-store-single-card/cccx-store-single-card.vue'),
  // 设计师组件
  'SheinXList': () => import(/* webpackChunkName: "cccx-shein-x-list" */'../campaigns-components/cccx-shein-x-list/cccx-shein-x-list.vue'),
  // 设计师投票组件
  'SheinXVote': () => import(/* webpackChunkName: "cccx-shein-x-vote" */'../campaigns-components/cccx-shein-x-vote/cccx-shein-x-vote.vue'),
  // 图文组件
  'TextEdit': () => import(/* webpackChunkName: "cccx-text-edit" */'../campaigns-components/cccx-text-edit/cccx-text-edit.vue'),
  // 普通排行榜落地页
  'RankingList': () => import(/* webpackChunkName: "cccx-ranking-list" */'../campaigns-components/rank-page/index.vue'),
  // 品牌墙
  'BrandWall': () => import(/* webpackChunkName: "ccc-static-image" */'../campaigns-components/cccx-brand-wall/cccx-brand-wall.vue'),
  // 新排行榜落地页
  'HotRankingList': () => import(/* webpackChunkName: "cccx-hot-ranking-list" */'../campaigns-components/cccx-rank-hot/cccx-rank-hot.vue'),
  // a~z品牌列表
  'AZBrandList': () => import(/* webpackChunkName: "cccx-az-brand-list" */'../campaigns-components/cccx-az-list/cccx-az-list.vue'),
  // 视频组件
  'Video': () => import(/* webpackChunkName: "cccx-video" */'../campaigns-components/cccx-video/cccx-video.vue'),
  // 直降组件
  'FallList': () => import(/* webpackChunkName: "cccx-fall-list" */'../campaigns-components/cccx-fall-list/cccx-fall-list.vue'),
  // 节省价组件
  'SaveList': () => import(/* webpackChunkName: "cccx-save-list" */'../campaigns-components/cccx-save-list/cccx-save-list.vue'),
  // 订阅组件
  'Subscribe': () => import(/* webpackChunkName: "cccx-subscribe" */'../campaigns-components/cccx-subscribe/cccx-subscribe.vue'),
  // 品类品牌单品组件
  'CategoryBrandItem': () => import(/* webpackChunkName: "cccx-category-brand-item" */'../campaigns-components/cccx-category-brand-item/cccx-category-brand-item.vue'),
  // 品牌横滑组件
  'BrandPavilionSideSlip': () => import(/* webpackChunkName: "cccx-brand-pavilion-side-slip" */'../campaigns-components/cccx-brand-pavilion-side-slip/cccx-brand-pavilion-side-slip.vue'),
  // 领积分
  'Integral': () => import(/* webpackChunkName: "cccx-integral" */'../campaigns-components/cccx-integral/cccx-integral.vue'),
  // 闪购
  'FlashSaleBanner': () => import(/* webpackChunkName: "cccx-flash-sale" */'../campaigns-components/cccx-flash-sale/cccx-flash-sale.vue'),
  // 左图闪购
  'LeftFlashSale': () => import(/* webpackChunkName: "cccx-left-flash-sale" */'../campaigns-components/cccx-flash-sale/cccx-left-flash-sale.vue'),
  // 闪购卡片
  'FlashSaleDynamic': () => import(/* webpackChunkName: "cccx-flash-sale-card" */'../campaigns-components/cccx-flash-sale/cccx-flash-sale-card.vue'),
  // 左图闪购卡片
  'LeftFlashSaleDynamic': () => import(/* webpackChunkName: "cccx-left-flash-sale-card" */'../campaigns-components/cccx-flash-sale/cccx-left-flash-sale-card.vue'),
  // 二分之一容器组件
  'HalfComponentContainer': () => import(/* webpackChunkName: "cccx-half-comp" */'../campaigns-components/cccx-half/index.vue'),
  // 上图下商品组件
  'AbovePictureItems': () => import(/* webpackChunkName: "cccx-above-picture-items" */'../campaigns-components/cccx-above-picture-items/cccx-above-picture-items.vue'),
  // 专题类目分流器组件
  'SbcSale': () => import(/* webpackChunkName: "cccx-sbc-sale" */'../campaigns-components/sbc-sale/index.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  prev[`${cur[0]}`] = lazyComponent({
    // propWhole: true,
    componentFactory: cur[1],
    customProps: {
      index: 'number',
      propData: 'object',
      propHooks: 'object',
      content: 'array',
      context: 'object',
      sceneData: 'object',
      cateLinks: 'object',
      language: 'object',
      brand: 'string',
      isLast: 'boolean',
      isLoad: 'boolean',
      lazyForceShow: 'boolean'
    }
  })
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  }
  return prev
}, {})
components['ClientOnly'] = ClientOnly

export default components
