<script lang="jsx">
export default {
  name: 'EmptyNode',
  functional: true,
  render(_, context) {
    // 直接返回子节点
    return context.children
  }
}
</script>
