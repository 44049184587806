var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowComp && _vm.loadComplete)?_c('div',{ref:"immersiveBannerRef",staticClass:"c-immersiveBanner__area has-bottom-space",style:({
    position: 'relative',
    marginTop: `-${_vm.commonHeight?.topBgHeight + _vm.commonHeight?.moveUpHeight}rem`,
    zIndex: '1',
  })},[_c('div',{staticClass:"c-immersiveBanner__warp"},[_c('div',{staticClass:"c-immersiveBanner__static",style:({
        height: `${ _vm.commonHeight?.realBannerHeight }rem`,
      })},[_c('ImmersiveStaticBg',{attrs:{"item":_vm.showItems,"cutImg":_vm.cutImg,"activeIndex":_vm.activeIndex}}),_vm._v(" "),_c('div',{staticClass:"c-immersiveBanner__trans-placeholder",style:({ backgroundColor: _vm.commonHeaderBg?.backgroundColor || '#fff' })})],1),_vm._v(" "),_c('div',{staticClass:"c-immersiveBanner__real",style:({
        width: 'calc(10rem - 16px)',
        height: `calc(${_vm.commonHeight?.bottomBgHeight - _vm.commonHeight?.moveUpHeight}rem - .2667rem)`,
        margin: '0 8px',
        bottom: `.2667rem`
      })},[_c('swiper-container',{ref:"realBannerSwiper",staticClass:"c-immersiveBanner__real-swiper",attrs:{"init":"false","destroy-on-disconnected":"false","dir":_vm.swiperDir},on:{"click":_vm.handleSlideClick,"swiperslidechangetransitionend":_vm.transitionEnd}},_vm._l((_vm.showItems),function(item,index){return _c('swiper-slide',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', { item: _vm.analysisCateItem(item, index), index, useBffApi: true })),expression:"getAnalysisData('2-22-2', { item: analysisCateItem(item, index), index, useBffApi: true })"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', { item: _vm.analysisCateItem(item, index), index, useBffApi: true })),expression:"getAnalysisData('2-22-1', { item: analysisCateItem(item, index), index, useBffApi: true })"}],key:`${item.FE_ID}`,staticClass:"c-immersiveBanner__real-slide",class:{
            'c-immersiveBanner__list-recommend-out': _vm.isListRecommendMainImageReplace
          },attrs:{"data-key":`${item.FE_ID}`,"data-realswiperIndex":index}},[(Number(item.bannerType) === 2 && item.themeColor)?_c('div',{staticClass:"c-immersiveBanner__bgImage gradientColor",style:({
              backgroundImage: `${item.bannerBgColor}`,
              height: `${item.realBannerHeight}rem`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: `10rem auto`,
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              transform: `translate(-8px, -${item.topBgHeight + _vm.commonHeight?.moveUpHeight}rem)`
            })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c-immersiveBanner__bgImage",class:{'fsp-element': item.FE_ID == _vm.showItems[0].FE_ID && !_vm.hasShowed},style:({
              backgroundImage: `url(${ item.image.src })`,
              height: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: `10rem auto`,
              backgroundPosition: `-8px -${item.topBgHeight + _vm.commonHeight?.moveUpHeight}rem`,
              overflow: 'hidden',
              zIndex: 2,
              position: 'absolute',
            }),attrs:{"data-background-image":index === 0 ? (item?.image?.src ?? '') : null}}),_vm._v(" "),_c('LazyHydrationWrapper',{attrs:{"when-visible":true}},[(Number(item.bannerType) === 2)?_c('ImmersiveProduct',{class:{'not-fsp-element': _vm.hasShowed},attrs:{"item":item,"index":index,"positionTypeMap":_vm.positionTypeMap,"data-key":`${index}_${_vm.positionTypeMap[`${item.positionType}`][0]}_0`,"textBtnColor":_vm.textBtnColor,"cssRight":_vm.cssRight,"item-config":_vm.productItemConfig,"isR56":_vm.isH1R56,"clickProduct":_vm.clickProduct,"analysisCateItem":_vm.analysisCateItem,"bannerIns":_vm.bannerIns}}):_vm._e()],1),_vm._v(" "),_c('LazyHydrationWrapper',{attrs:{"when-visible":true}},[(_vm.newBannerType.includes(Number(item.bannerType)))?_c('ImmersiveNewProduct',{class:{'not-fsp-element': _vm.hasShowed},attrs:{"item":item,"index":index,"bannerType":Number(item.bannerType),"cssRight":_vm.cssRight,"item-config":_vm.productItemConfig,"isR56":_vm.isH1R56,"clickProduct":_vm.clickProduct,"analysisCateItem":_vm.analysisCateItem,"bannerIns":_vm.bannerIns}}):_vm._e()],1),_vm._v(" "),(item?.bannerImage?.src)?[(index === 0)?_c('img',{staticClass:"gif-banner not-fsp-element",attrs:{"src":item?.bannerImage?.src,"no-format":""}}):_c('img',{staticClass:"gif-banner not-fsp-element lazyload",attrs:{"data-src":item?.bannerImage?.src,"data-exp":"useWebp=0"}})]:_vm._e(),_vm._v(" "),(item?.topBannerImage?.src)?_c('BaseImg',{staticClass:"c-immersiveBanner__bgLayer",style:(_vm.cutImgStyle(item)),attrs:{"first-screen":true,"is-support-webp":true,"img-design-width":359 * 3,"img-src":item?.topBannerImage?.src,"position":"left top","fit":"cover"}}):_vm._e()],2)}),1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }