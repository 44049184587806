export function getAnalysisData(id, { item, index = 0, prefix, callback, ...arg } = {}, setCode = false) {
  const data = {
    sceneData: this.sceneData,
    propData: this.propData,
    compIndex: this.index,          // 组件所在位置
    item,                           // item
    index,                          // 当前item在组件中的位置
    ...arg
  }
    
  let code = ''
  if (typeof setCode === 'boolean' && setCode) code = this.code
  if (typeof setCode === 'string') code = setCode
    
  return {
    id,
    code,
    prefix: prefix ? prefix : this.sceneData.pageFrom,
    data,
    callback: callback && typeof callback === 'function' ? callback : () => {}
  }
}
  
