<template>
  <div class="choice-label__wrapper">
    <BaseImg
      class="choice-label__icon"
      :img-src="choiceLabelImg"
      :img-design-width="153"
      :first-screen="firstScreen"
    />
  </div>
</template>

<script setup>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { useGetChoiceLabelImg } from 'public/src/pages/components/ccc/components/shop-banner/hooks/useGetChoiceLabelImg.js'

defineProps({
  firstScreen: {
    type: Boolean,
    default: true
  }
})

const { choiceLabelImg } = useGetChoiceLabelImg()

</script>

<style lang="less" scoped>
.choice-label {
  &__wrapper {
    display: flex;
    height: 16 / @w-375rem;
  }
  &__icon {
    width: 51 / @w-375rem;
    height: 100%;
  }
}
</style>
