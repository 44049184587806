var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.showBannerLabel)?_c('div',{staticClass:"branch-shop-banner-lablels-container in-short-shopbanner",on:{"click":function($event){$event.stopPropagation();return _setup.onLabelClick.apply(null, arguments)}}},[_c('div',{staticClass:"branch-shop-banner-lablels-left"},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '2-22-65',
        data: {
          storeBannerLabel: _setup.storeBannerLabel,
        },
      }),expression:"{\n        id: '2-22-65',\n        data: {\n          storeBannerLabel,\n        },\n      }"}],ref:"bannerLabelRef",staticClass:"branch-shop-banner-tagsline fsp-element"},_vm._l((_setup.storeBannerLabel),function(servicePolicyLabelItem){return _c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
          id: '2-22-66',
          data: {
            label_content: servicePolicyLabelItem.type,
          },
        }),expression:"{\n          id: '2-22-66',\n          data: {\n            label_content: servicePolicyLabelItem.type,\n          },\n        }"}],key:servicePolicyLabelItem.type,staticClass:"tagsline-item"},[(['FreeShipping', 'PriceProtect'].includes(servicePolicyLabelItem.type))?_c('img',{staticClass:"tagsline-item__img",attrs:{"src":_setup.labelIconMap[servicePolicyLabelItem.type]}}):_c('img',{staticClass:"tagsline-item__img",attrs:{"src":`${_vm.constantsData.PUBLIC_CDN}/pwa_dist/images/ccc/shop_banner_labels/${servicePolicyLabelItem.type}.png`}}),_vm._v(" "),_c('div',{staticClass:"tagsline-item__desc"},[_vm._v("\n          "+_vm._s(servicePolicyLabelItem.title)+"\n        ")])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }