import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'
// 业务监控
import HomeMonitor from 'public/src/pages/common/home_common/monitor.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'
import { metricCccClick } from 'public/src/pages/common/business-monitor/common.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'


export default {
  inject: {
    injectsDropCart: { default: () => {} },
    metricsConfig: { default: () => {} }
  },
  computed: {
    metaData() {
      return this.propData?.props?.metaData || {}
    },
    code() {
      const { componentKey, id } = this.propData
      return `${componentKey}${this.index}${id}`
    },
    cccLink() {
      return new CccLink({
        sceneData: this.sceneData,
        propData: this.propData
      })
    },
    // 卡片化字段为false，或者边距、圆角 这2项都获取失败或为空时，代表非卡片化样式，展示原有样式
    homeCompIsCardStyle() {
      const { metaData = {} } = this.propData?.props || {}
      const {
        isCardShow = '0',
        margin: marginList = [],
        cardRadius = '0',
      } = metaData || {}
      if (Number(isCardShow) !== 1) return false
      if (Number(isCardShow) || Number(cardRadius) > 0) return true
      return Array.isArray(marginList) && marginList.some((item) => Number(item) > 0)
    },
    homeCompCardStyle() {
      const { metaData = {} } = this.propData?.props || {}
      const { margin = [], cardRadius = '0' } = metaData || {}
      const marginList =
        Array.isArray(margin) && margin.length === 4
          ? margin.slice()
          : Array(4).fill(0)
      const marginStyle = marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
      const styleResult = {
        margin: marginStyle,
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)',
      }
      styleResult.borderRadius = `${Number(cardRadius)}px`
      return (this.homeCompIsCardStyle) ? styleResult : {}
    },
  },
  methods: {
    jumpLink({ url, hrefType, routeType = 'push', scene = 'cccx' }) {
      if (url.indexOf('javascript:;') > -1) return

      try {
        // 点击CCC 组件监控
        if (this.metricsConfig?.reportMetrics?.cccClick) {
          metricCccClick({ 
            page: this.metricsConfig?.pageName || '',
            component_type: this.propData?.styleType || '',
            position: `${this.index + 1}`,
          })
        }
      } finally {
        if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
          markPoint('toNextPageClick', 'public')
          this.handleClickToList({ url, hrefType })
          if(window?._gb_app_) {
            this.$router[routeType](url)
          } else { // 兼容多页存在无_gb_app_实例case
            location.href = url
          }
        } else {
          sessionStorage.setItem('navtrack_to', 'page_campaigns')
          sessionStorage.setItem('navtrack_point', Date.now())
          location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
        }
      }

    },
    // 获取埋点信息
    getAnalysisData(id, { item, index = 0, prefix, callback, ...arg } = {}, setCode = false) {
      const data = {
        sceneData: this.sceneData,
        propData: this.propData,
        compIndex: this.index,          // 组件所在位置
        item,                           // item
        index,                          // 当前item在组件中的位置
        ...arg
      }

      let code = ''
      if (typeof setCode === 'boolean' && setCode) code = this.code
      if (typeof setCode === 'string') code = setCode
      return {
        id,
        code,
        prefix: prefix ? prefix : this.sceneData.pageFrom,
        data,
        callback: callback && typeof callback === 'function' ? callback : ''
      }
    },

    /**
     * 获取商品类埋点信息
     * @param {*} cate ccc配置的内容，包含hrefType, hrefTarget这些内容
     * @param {*} tab tab的信息，包含，tab的位置index，cateType(tab内容类型)，cateId(tab id)
     * @returns
     */
    getProductAnalysis(id, { type = 'click', index, prefix, item, isNew, cate, fault, tab, flash_type, poskey, isJumpToList, name = '', compIndex = '', act_nm, isPopup, style, extendSaParams, callback, abtest, useBffApi, setCode = false, queryTs }) {
      const analysisData = {
        name,
        item: cate,
        index,
        prefix,
        product: item,
        tab: tab,        // 当前的tab
        flash_type, // ⚠️也不要有类似这样的具有特殊类型特定组件的字段
        poskey,
        // ⚠️不要扩展类似下面两个变量的类型，应该新开一个字段统一维护
        isJumpToList, // 是否跳到列表(闪购、一口价列表等)
        fault, // 商品是否是容错请求之后的商品
        act_nm,
        isNew,
        isPopup,
        style,
        extendSaParams, // 外部直接传入sa相关数据
        callback,
        abtest,
        useBffApi, // 是否bff组件
        queryTs,
      }

      if (typeof compIndex === 'number') Object.assign(analysisData, { compIndex })
      let code = type === 'expose' ? `${this.code}_${tab?.index}_product` : ''
      if (typeof setCode === 'string') code = setCode
      return this.getAnalysisData(id, analysisData, code)
      // type 为曝光的时候 需要给埋点信息加上唯一code (商品类)
    },
    
    // 跳转落地页通用方法
    handleClickToPage(
      { 
        hrefType, 
        hrefTarget, 
        webClickUrl, 
        isBff, 
        item_loc, 
        relativeUrl = '', 
        itemIndex, 
        poskey, 
        adp,
        contentCarrierId_adp,
      }, 
      needAnalysis = true
    ) {
      const url = this.cccLink.getFullLink({
        item: {
          hrefType,
          hrefTarget,
          webClickUrl,
          ...(relativeUrl ? { relativeUrl } : {}),
          ...(adp ? { adp } : {}),
          ...(item_loc ? { item_loc: item_loc } : {}),
          ...(contentCarrierId_adp ? { contentCarrierId_adp } : {})
        },
        index: itemIndex,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        isBff,
      })
      needAnalysis && this.sendAnalysis({
        hrefType,
        hrefTarget,
        itemIndex,
        poskey,
      })
      if (!url || url.indexOf('javascript:;') > -1) return
      this.jumpLink({ url, hrefType })
    },

    // 跳转列表页（第一次使用单页水合）
    handleClickToList({ url, hrefType }) {
      const listTypes = ['real', 'itemPicking', 'frontCat'] // 真实、选品、 前台类目
      if (!listTypes.includes(hrefType)) return // 只有跳转到列表页才会触发单页水合

      preloadGoodsRequest.triggerNotice(url)
      preloadGoodsRequest.click(url)
    },

    sendAnalysis({ hrefType, hrefTarget, itemIndex, poskey }) {
      daEventCenter.triggerNotice({
        daId: '2-22-2',
        bindData: {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: this.index,
          index: itemIndex,
          poskey,
          item: {
            hrefType,
            hrefTarget,
          },
        },
      })
    },
    // 隐藏组件告警
    hideCompMonitor(params) {
      let info = {
        tags: {
          page: this.metricsConfig?.pageName || '',
          component_type: this.propData?.styleType || '',
          reason: params?.tags?.reason || '',
        },
        message: params?.message || ''
      }
      HomeMonitor.metricCompFilter(info)
    },
    // 组件点击告警
    clickCompMonitor() {
      if (!this.metricsConfig?.reportMetrics?.cccClick) return
      metricCccClick({
        page: this.metricsConfig?.pageName || '',
        component_type: this.propData?.styleType || '',
        position: `${this.index + 1}`,
      })
    },
    // 隐藏组件日志上报
    hideCompSls({ logLevel = 1, tag = '', message = '', reason = '', ...extraInfo } = {}, immediate = false) {
      HomeSlslog.slsCompFilter({
        logLevel,
        tag,
        message,
        reason,
        extraInfo,
        module: this.propData?.styleType || '',
        tabName: this.sceneData?.tabName || '',
        pageName: this.sceneData?.pageType || '',
        cccId: this.propData?.componentId || '',
        position: `${this.index + 1}`,
      }, immediate)
    }
  },
}

