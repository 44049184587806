var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-drawer',{attrs:{"append-to-body":true,"animate-type":"half","max-size":"80%","no-header":"","custom-class":"cccStoreBanner-drawer","visible":_vm.localVisible},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('div',{staticClass:"close-icon",on:{"click":_vm.closeDrawer}},[_c('i',{staticClass:"suiiconfont sui_icon_close_18px"})]),_vm._v(" "),_c('div',{staticClass:"cccStoreBanner-drawer-content"},[_c('div',{attrs:{"lass":"cccStoreBanner-drawer-content_header"}},[(!_vm.isShowBranchShop && _vm.shopData.storeBG)?_c('section',{staticClass:"cccStoreBanner-drawer__banner-bg",style:({
          'backgroundImage': `url(${_vm.shopData.storeBG})`
        })}):_vm._e(),_vm._v(" "),_c('section',{staticClass:"shop-drawer__header",class:[_vm.isShowBranchShop ? 'shop-drawer__header-branch' : 'shop-drawer__header-ordinary']},[(_vm.shopData.logo)?_c('div',{staticClass:"shop-drawer__header-logo"},[_c('img',{attrs:{"src":_vm.shopData.logo.replace(/^https?:/, ''),"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"shop-drawer__header-detail"},[_c('div',{staticClass:"shop-drawer__header-detail-top"},[(_vm.shopData.title)?_c('div',{staticClass:"shop-drawer__header-detail-title"},[_c('span',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm.shopData.title || ''))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"tag_wrap"},[(!!_vm.trendsLabelInfo.status)?_c('TrendLabel',{attrs:{"trends-label-info":_vm.trendsLabelInfo}}):_vm._e(),_vm._v(" "),(_vm.isShowChoiceLabel)?_c('ChoiceLabel'):_vm._e(),_vm._v(" "),(_vm.isShowBranchShop)?_c('div',{staticClass:"shop-drawer__header-detail-tagWrap"},[_c('img',{staticClass:"branchIcon",attrs:{"src":`${_vm.PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.storeBannerLang.branchShopTag))])]):_vm._e(),_vm._v(" "),(_vm.isPreferredSeller)?_c('PreferredSellerLabel',{attrs:{"label-icon":`${_vm.PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`,"label-text":_vm.storeBannerLang.bestSaleTag}}):_vm._e(),_vm._v(" "),(_vm.formattedData?.localSellerLabel)?_c('LocalSellerLabel',{attrs:{"label-text":_vm.formattedData.localSellerLabel}}):_vm._e()],1)])])]),_vm._v(" "),_c('div',{staticClass:"cccStoreBanner-drawer-content_body",class:{
        'with-footer-followbtn': _vm.showFooterFollowBtn,
      }},[(_vm.shortBannerDetailInfo.length > 1)?_c('div',{staticClass:"short-banner-drawer__detail-info"},_vm._l((_vm.shortBannerDetailInfo),function(item){return _c('div',{key:item.name,staticClass:"short-banner-drawer__info-item"},[_c('h3',{staticClass:"short-banner-drawer__info-num"},[_vm._v("\n            "+_vm._s(item.num)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"short-banner-drawer__info-name"},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])])}),0):_vm._e(),_vm._v(" "),(_vm.storeBannerLabel.length > 0)?_c('div',{staticClass:"shop-drawer-labels-container"},_vm._l((_vm.storeBannerLabel),function(labelItem){return _c('div',{key:labelItem.type,staticClass:"shop-drawer-labels-item"},[_c('div',{staticClass:"shop-drawer-labels-item_title"},[(['FreeShipping', 'PriceProtect'].includes(labelItem.type))?_c('img',{staticClass:"shop-drawer-labels-item__img",attrs:{"src":labelItem.icon || _vm.labelIconMap[labelItem.type]}}):_c('img',{class:[
                'shop-drawer-labels-item__img',
                `shop-drawer-labels-item__img-${labelItem.type}`
              ],attrs:{"src":labelItem.icon || `${_vm.PUBLIC_CDN}/pwa_dist/images/ccc/shop_banner_labels_pop/${labelItem.type}.png`}}),_vm._v("\n            "+_vm._s(labelItem.title)+"\n          ")]),_vm._v(" "),(labelItem.desc)?_c('div',{staticClass:"shop-drawer-labels-item_desc"},[_vm._v("\n            "+_vm._s(labelItem.desc)+"\n          ")]):_vm._e(),_vm._v(" "),(labelItem.url)?_c('div',{staticClass:"shop-drawer-labels-item_view-more",on:{"click":function($event){return _vm.showViewMore(labelItem.url)}}},[_vm._v("\n            "+_vm._s(labelItem.viewMore)+"\n            "),_c('i',{staticClass:"suiiconfont sui_icon_more_right_16px"})]):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.isShowDescriptions && _vm.storeBannerLabel.length > 0)?_c('div',{staticClass:"shop-drawer-content-line-gap"}):_vm._e(),_vm._v(" "),(_vm.isShowDescriptions)?_c('div',{staticClass:"shop-drawer-descriptions-container"},[_c('div',{staticClass:"shop-drawer__title"},[_c('img',{staticClass:"shop-drawer-descriptions__img",attrs:{"src":"https://img.ltwebstatic.com/images3_ccc/2024/06/17/57/1718614421baf56c26901f42766667cefb3b709714.png"}}),_vm._v("\n          "+_vm._s(_vm.storeBannerLang.descTitle)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"shop-drawer__content"},[_vm._v("\n          "+_vm._s(_vm.shopData.descriptions || '')+"\n        ")])]):_vm._e()]),_vm._v(" "),(_vm.showFooterFollowBtn)?_c('footer',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '2-22-20',
        data: { item: _vm.followBtnAnalysisData }
      }),expression:"{\n        id: '2-22-20',\n        data: { item: followBtnAnalysisData }\n      }"}],staticClass:"cccStoreBanner-drawer__footer"},[_c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
          id: '2-22-21',
          data: { item: _vm.followBtnAnalysisData }
        }),expression:"{\n          id: '2-22-21',\n          data: { item: followBtnAnalysisData }\n        }"}],staticClass:"cccStoreBanner-drawer__follow-btn",class:[
          _vm.isStoreFollowed ? 'followed' : 'unfollow'
        ],on:{"click":function($event){$event.stopPropagation();return _vm.onFollowBtnClick.apply(null, arguments)}}},[_c('i',{staticClass:"cccStoreBanner-drawer__follow-btn-icon"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.isStoreFollowed ? _vm.language.followingLang : _vm.language.followLang))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }