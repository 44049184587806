<!-- eslint-disable @shein-aidc/cccx/notUseImgTag-->
<template>
  <s-drawer
    :append-to-body="true"
    animate-type="half"
    max-size="80%"
    no-header
    custom-class="cccStoreBanner-drawer"
    :visible.sync="localVisible"
  >
    <div
      class="close-icon"
      @click="closeDrawer"
    >
      <i class="suiiconfont sui_icon_close_18px"></i>
    </div>
    <div class="cccStoreBanner-drawer-content">
      <div lass="cccStoreBanner-drawer-content_header">
        <!-- 普通店铺的背景图 -->
        <section
          v-if="!isShowBranchShop && shopData.storeBG"
          class="cccStoreBanner-drawer__banner-bg"
          :style="{
            'backgroundImage': `url(${shopData.storeBG})`
          }"
        ></section>
        <section
          class="shop-drawer__header"
          :class="[isShowBranchShop ? 'shop-drawer__header-branch' : 'shop-drawer__header-ordinary']"
        >
          <div
            v-if="shopData.logo"
            class="shop-drawer__header-logo"
          >
            <img
              :src="shopData.logo.replace(/^https?:/, '')"
              aria-hidden="true"
            />
          </div>
          <div class="shop-drawer__header-detail">
            <!-- 店铺名 -->
            <div class="shop-drawer__header-detail-top">
              <div
                v-if="shopData.title"
                class="shop-drawer__header-detail-title"
              >
                <span class="text-ellipsis">{{ shopData.title || '' }}</span>
              </div>
            </div>
            <div
              class="tag_wrap"
            >
              <!-- trends label -->
              <TrendLabel
                v-if="!!trendsLabelInfo.status"
                :trends-label-info="trendsLabelInfo"
              />
              <!-- choice label -->
              <ChoiceLabel v-if="isShowChoiceLabel" />
              <!-- brand label -->
              <div
                v-if="isShowBranchShop"
                class="shop-drawer__header-detail-tagWrap"
              >
                <img
                  :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                  class="branchIcon"
                />
                <div>{{ storeBannerLang.branchShopTag }}</div>
              </div>
              <!-- 优选卖家 label -->
              <PreferredSellerLabel
                v-if="isPreferredSeller"
                :label-icon="`${PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`"
                :label-text="storeBannerLang.bestSaleTag"
              />
              <!--本地卖家-->
              <LocalSellerLabel
                v-if="formattedData?.localSellerLabel"
                :label-text="formattedData.localSellerLabel"
              />
            </div>
          </div>
        </section>
      </div>
      <div
        class="cccStoreBanner-drawer-content_body"
        :class="{
          'with-footer-followbtn': showFooterFollowBtn,
        }"
      >
        <div
          v-if="shortBannerDetailInfo.length > 1"
          class="short-banner-drawer__detail-info"
        >
          <div
            v-for="item in shortBannerDetailInfo"
            :key="item.name"
            class="short-banner-drawer__info-item"
          >
            <h3 class="short-banner-drawer__info-num">
              {{ item.num }}
            </h3>
            <p class="short-banner-drawer__info-name">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div
          v-if="storeBannerLabel.length > 0"
          class="shop-drawer-labels-container"
        >
          <div
            v-for="labelItem in storeBannerLabel"
            :key="labelItem.type"
            class="shop-drawer-labels-item"
          >
            <div class="shop-drawer-labels-item_title">
              <img 
                v-if="['FreeShipping', 'PriceProtect'].includes(labelItem.type)"
                class="shop-drawer-labels-item__img"
                :src="labelItem.icon || labelIconMap[labelItem.type]"
              />
              <img
                v-else
                :src="labelItem.icon || `${PUBLIC_CDN}/pwa_dist/images/ccc/shop_banner_labels_pop/${labelItem.type}.png`"
                :class="[
                  'shop-drawer-labels-item__img',
                  `shop-drawer-labels-item__img-${labelItem.type}`
                ]"
              />
              {{ labelItem.title }}
            </div>
            <div
              v-if="labelItem.desc"
              class="shop-drawer-labels-item_desc"
            >
              {{ labelItem.desc }}
            </div>
            <div
              v-if="labelItem.url"
              class="shop-drawer-labels-item_view-more"
              @click="showViewMore(labelItem.url)"
            >
              {{ labelItem.viewMore }}
              <i class="suiiconfont sui_icon_more_right_16px"></i>
            </div>
          </div>
        </div>
        <div
          v-if="isShowDescriptions && storeBannerLabel.length > 0"
          class="shop-drawer-content-line-gap"
        ></div>
        <div 
          v-if="isShowDescriptions"
          class="shop-drawer-descriptions-container"
        >
          <div class="shop-drawer__title">
            <img
              src="https://img.ltwebstatic.com/images3_ccc/2024/06/17/57/1718614421baf56c26901f42766667cefb3b709714.png"
              class="shop-drawer-descriptions__img"
            />
            {{ storeBannerLang.descTitle }}
          </div>
          <div class="shop-drawer__content">
            {{ shopData.descriptions || '' }}
          </div>
        </div>
      </div>
      <footer
        v-if="showFooterFollowBtn"
        v-expose="{
          id: '2-22-20',
          data: { item: followBtnAnalysisData }
        }"
        class="cccStoreBanner-drawer__footer"
      >
        <div
          v-tap="{
            id: '2-22-21',
            data: { item: followBtnAnalysisData }
          }"
          class="cccStoreBanner-drawer__follow-btn"
          :class="[
            isStoreFollowed ? 'followed' : 'unfollow'
          ]"
          @click.stop="onFollowBtnClick"
        >
          <i class="cccStoreBanner-drawer__follow-btn-icon"></i>
          <span>{{ isStoreFollowed ? language.followingLang : language.followLang }}</span>
        </div>
      </footer>
    </div>
  </s-drawer>
</template>
<script>
import { Drawer as SDrawer } from '@shein/sui-mobile'
import ChoiceLabel from './widgets/data-labels/ChoiceLabel.vue'
import LocalSellerLabel from './widgets/data-labels/LocalSellerLabel.vue'
import PreferredSellerLabel from './widgets/data-labels/PreferredSellerLabel.vue'
import TrendLabel from './widgets/data-labels/TrendLabel.vue'
import { FollowBtnScene } from './hooks/use-store-folllow-state'
import { useGetChoiceLabelImg } from 'public/src/pages/components/ccc/components/shop-banner/hooks/useGetChoiceLabelImg.js'

const { PUBLIC_CDN = '', langPath = '', SiteUID } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const labelIconMap = {
  FreeShipping: 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/dc/1728548543f6623cfaa17b1cfd9fda4cbd53dbd9de.png',
  PriceProtect: 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/94/1728548574e74243cb95c87bd7a75727fc51871de1.png',
}

export default {
  components: {
    SDrawer,
    TrendLabel,
    ChoiceLabel,
    LocalSellerLabel,
    PreferredSellerLabel,
  },
  inject: ['isShowChoiceLabel'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shopData: {
      type: Object,
      default() {
        return {}
      }
    },
    storeBannerLang: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formattedData: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isStoreFollowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      shortBannerDetailInfo: [],
      isTriggerByDrawerFooterFollowBtn: false,
      labelIconMap,
    }
  },
  setup() {
    const { choiceLabelImg } = useGetChoiceLabelImg()
    return {
      choiceLabelImg
    }
  },
  computed: {
    // 店招是否命中新样式（品牌店&&命中实验）
    isShowBranchShop() {
      return this.shopData?.storeBusinessType === 2
    },
    isPreferredSeller() {
      return this.formattedData?.isPreferredSeller
    },
    storeBannerLabel() {
      const choiceLabel = {
        type: 'ChoiceLabel',
        title: '',
        icon: this.choiceLabelImg,
        desc: this.language.SHEIN_KEY_PWA_32371,
        viewMore: this.language.SHEIN_KEY_PWA_30045,
        url: ['pwus', 'mjp'].includes(SiteUID) ? langPath + '/choices-terms-a-5181.html' : ''
      }
      const bannerLabel = this.formattedData?.bannerLabel || []
      this.isShowChoiceLabel && bannerLabel.unshift(choiceLabel)
      return bannerLabel
    },
    trendsLabelInfo(){
      return this.shopData.trendsLabelInfo || {}
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update:visible', v)
      }
    },
    isShowDescriptions(){
      return !!this.formattedData?.isShowDescriptions
    },
    showFooterFollowBtn() {
      return this.formattedData.showDrawerFooterFollowBtn
    },
    followBtnAnalysisData() {
      const { storeCode } = this.shopData
      return {
        storeCode,
        followsStatus: this.isStoreFollowed,
        scene: FollowBtnScene.DRAWER_FOOTER,
      }
    }
  },
  mounted() {
    this.initShortBannerDetailInfo()
  },
  methods: {
    closeDrawer() {
      this.localVisible = false
    },
    showViewMore(url) {
      window.location.href = url
    },
    initShortBannerDetailInfo() {
      const { shopData, formattedData } = this
      const { ratingLang, itemsLang, followsCountLang } = this.storeBannerLang
      const { followsCount } = shopData
      const { storeScores, storeGoodsQuantity } = formattedData ?? {}
      if (storeScores) {
        this.shortBannerDetailInfo.push({
          name: ratingLang,
          num: storeScores,
        })
      }
      if (storeGoodsQuantity && storeGoodsQuantity !== '0') {
        this.shortBannerDetailInfo.push({
          name: itemsLang,
          num: storeGoodsQuantity,
        })
      }
      if (followsCount && followsCount !== '0') {
        this.shortBannerDetailInfo.push({
          name: followsCountLang,
          num: followsCount,
        })
      }
    },
    onFollowBtnClick() {
      const { storeCode } = this.shopData
      appEventCenter.$emit('FlowStoreFolloingClickFollow', {
        storeCode,
        scene: FollowBtnScene.DRAWER_FOOTER,
      })
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern  */
.cccStoreBanner-drawer {
  box-sizing: border-box;
  min-height: 436/75rem;
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */

  .S-drawer__body {
    width: 100%;
    font-family: Helvetica,Arial,sans-serif;
    font-style: normal;
    font-size: 24/75rem;
    color: #000;
    line-height: 32/75rem;
    box-sizing: border-box;
    overflow: visible !important; /* stylelint-disable-line declaration-no-important */

    .close-icon {
      padding: 6px 6px 10px 10px;
      position: absolute;
      transform: scale(0.7);
      transform-origin: top right;
      top: 0;
      right: 0;
      z-index: 10; /* stylelint-disable-line  */
      i {
        color: #000;
      }
    }

    >p {
      width: 100%;
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  &__banner-bg {  /* stylelint-disable-line  */
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background-size: cover;
    background-position: center;
    filter: opacity(0.1);
    &::after {  /* stylelint-disable-line  */
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32.03%, #FFFFFF 94.16%);
    }
  }
  .cccStoreBanner-drawer-content{
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
  }
  .cccStoreBanner-drawer-content_header{
    position: relative;
    flex-shrink: 0;
  }
  .cccStoreBanner-drawer-content_body{
    overflow-y: auto;
    padding:12px 0 24px;
    &.with-footer-followbtn {
      padding-bottom: 56/37.5rem;
    }
  }

  // 详情弹窗底部
  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 56/37.5rem;
    background-color: #fff;
    padding: 8/37.5rem 12/37.5rem 0;
  }
  &__follow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40/37.5rem;
    font-size: 16px;
    line-height: 1.1875;
    font-weight: 700;
    font-family: 'SF-UI-Text-Bold';
    &.unfollow {
      color: #fff;
      background-color: #000;
      .cccStoreBanner-drawer__follow-btn-icon {
        background: url(https://img.ltwebstatic.com/images3_ccc/2024/08/19/71/1724072385c4ade76eaa2d78a46687acd7bdb04e5b.png) 50% 50% / contain no-repeat;
      }
    }
    &.followed {
      color: rgba(0, 0, 0, 0.30);
      border: 1px solid rgba(34, 34, 34, 0.30);
      .cccStoreBanner-drawer__follow-btn-icon {
        background: url(https://img.ltwebstatic.com/images3_ccc/2024/08/19/7f/17240723627d94e7a58b7dcd743f9dcc55578bcba7.png) 50% 50% / contain no-repeat;
      }
    }
  }
  &__follow-btn-icon {
    display: block;
    width: 14/37.5rem;
    height: 14/37.5rem;
    margin-right: 4/37.5rem /* rtl:0 */;
    margin-left: 0 /* rtl:0.10667rem */;
  }
}

.shop-drawer {
  position: relative;
  height: 100%;
  padding: 32/75rem 0 22/75rem;

  &__header {
    .flexbox();
    flex-direction: row;
    position: relative;
    padding: 20/75rem 30/75rem 0;
  }

  &__header-branch {
    background: linear-gradient(180deg, #E2EDF3 0.34%, #FFFFFF 100%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);

  }

  &__header-logo {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
    border-radius: 4/75rem;
    width: 128/75rem;
    height: 128/75rem;
    margin-right: 32/75rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-top: -44/75rem;
    background-color: #fff;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header-detail {
    .flexbox();
    flex: 1;
    font-family: Helvetica,Arial,sans-serif;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }

  &__header-detail-top {
    .flexbox();
    align-items: center;
    flex-wrap: wrap;
    line-height: 40/75rem;
  }

  &__header-detail-title {
    .font-dpr(32px);
    font-weight: 700;
    text-transform: none;
    color: #333;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
  }

  &__header-detail-tagWrap {
    .flexbox();
    .border-dpr(border, 0.5px, #BCC8D3);
    .font-dpr(20px);
    height: 32/75rem;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(128.64deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
    border-radius: 4/75rem;
    color: #2D3540;
    padding: 3/75rem 4/75rem;
    align-items: center;
    font-weight: 500;

    >div {
      height: 24/75rem;
      line-height: 24/75rem;
    }
    .branchIcon {
      width: 24/75rem;
      margin-right: 2/75rem;
    }
  }
}

.short-banner-drawer {
  &__detail-info {
    display: flex;
    align-items: stretch;
    padding: 12/37.5rem 0;
    border-radius: 4/37.5rem;
    border: 1px solid #E5E5E5;
    background-color: #F6F6F6;
    margin: 4/37.5rem 16/37.5rem;
  }
  &__info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    &:not(:first-child) {
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 20/37.5rem;
        background-color: #E5E5E5;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__info-num {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1875;
    font-family: 'SF-UI-Text-Bold';
    color: #000;
  }
  &__info-name {
    font-size: 12px;
    line-height: 1.166667;
    font-family: 'SF-UI-Text-Regular';
    color: #767676;
    margin-top: 4/37.5rem;
  }
}

  .shop-drawer-labels-container{
    padding: .32rem /* 12/37.5 */ .426667rem /* 16/37.5 */  .426667rem;
    .shop-drawer-labels-item{
      &:not(:first-child){
        margin-top: .426667rem /* 16/37.5 */;
      }
    }
    .shop-drawer-labels-item_title{
      color: var(---sui_color_gray_dark1, #000);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      line-height: .426667rem;
    }
    .shop-drawer-labels-item__img{
      width: .426667rem /* 16/37.5 */;
      height: .426667rem /* 16/37.5 */;
      object-fit: cover;
      margin-right: .213333rem /* 8/37.5 */;
    }
    .shop-drawer-labels-item__img-ChoiceLabel {
      width: 51 / @w-375rem;
      height: 14 / @w-375rem;
      margin-right: 0;
    }
    .shop-drawer-labels-item_desc{
      margin-top: .266667rem /* 10/37.5 */;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 12px;
      line-height: 130%;
    }

    .shop-drawer-labels-item_view-more {
      display: flex;
      align-items: center;
      margin-top: .266667rem /* 10/37.5 */;
      color: var(---sui_color_primary, #2d68a7);
      font-size: 12px;
      line-height: 130%;
    }
   
  }
  .shop-drawer-content-line-gap{
      background: #F6F6F6;
      height: 10px;
  }
  .shop-drawer-descriptions-container{
    padding: 16px 16px;

    .shop-drawer__title{
      color: var(---sui_color_gray_dark1, #000);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .shop-drawer-descriptions__img{
      width: .426667rem /* 16/37.5 */;
      height: .426667rem /* 16/37.5 */;
      object-fit: cover;
      margin-right: .213333rem /* 8/37.5 */;
    }
    .shop-drawer__content{
      margin-top: 12px;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 12px;
      line-height: 130%; /* 15.6px */
    }
  }
</style>
<style lang="less" scoped>
.tag_wrap {
  display: flex;
  align-items: center;
  gap: 6 / @w-375rem 4 / @w-375rem;
  flex-wrap: wrap;
  margin-top: 4 / @w-375rem;
}
</style>
