<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div
    v-if="isShowComp && loadComplete"
    ref="immersiveBannerRef"
    class="c-immersiveBanner__area has-bottom-space"
    :style="{
      position: 'relative',
      marginTop: `-${commonHeight?.topBgHeight + commonHeight?.moveUpHeight}rem`,
      zIndex: '1',
    }"
  >
    <div class="c-immersiveBanner__warp">
      <!-- 底部静态背景轮播图 -->
      <div
        class="c-immersiveBanner__static"
        :style="{
          height: `${ commonHeight?.realBannerHeight }rem`,
        }"
      > 
        <ImmersiveStaticBg 
          :item="showItems"
          :cutImg="cutImg"
          :activeIndex="activeIndex"
        />
        <!--        底部透明占位-->
        <div
          class="c-immersiveBanner__trans-placeholder"
          :style="{ backgroundColor: commonHeaderBg?.backgroundColor || '#fff' }"
        >
        </div>
      </div>
      <!-- 真实滑动轮播图 -->
      <div
        class="c-immersiveBanner__real"
        :style="{
          width: 'calc(10rem - 16px)',
          height: `calc(${commonHeight?.bottomBgHeight - commonHeight?.moveUpHeight}rem - .2667rem)`,
          margin: '0 8px',
          bottom: `.2667rem`
        }"
      >
        <swiper-container
          ref="realBannerSwiper"
          init="false" 
          destroy-on-disconnected="false"
          :dir="swiperDir"
          class="c-immersiveBanner__real-swiper"
          @click="handleSlideClick"
          @swiperslidechangetransitionend="transitionEnd"
        >
          <swiper-slide
            v-for="(item, index) in showItems"
            :key="`${item.FE_ID}`"
            v-tap="getAnalysisData('2-22-2', { item: analysisCateItem(item, index), index, useBffApi: true })"
            v-expose="getAnalysisData('2-22-1', { item: analysisCateItem(item, index), index, useBffApi: true })"
            :data-key="`${item.FE_ID}`"
            :data-realswiperIndex="index"
            class="c-immersiveBanner__real-slide"
            :class="{
              'c-immersiveBanner__list-recommend-out': isListRecommendMainImageReplace
            }"
          >
            <!-- 渐变色 -->
            <div
              v-if="Number(item.bannerType) === 2 && item.themeColor"
              class="c-immersiveBanner__bgImage gradientColor"
              :style="{
                backgroundImage: `${item.bannerBgColor}`,
                height: `${item.realBannerHeight}rem`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: `10rem auto`,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                transform: `translate(-8px, -${item.topBgHeight + commonHeight?.moveUpHeight}rem)`
              }"
            >
            </div>
            <!-- "蒙层" 或 背景图 -->
            <div
              class="c-immersiveBanner__bgImage"
              :class="{'fsp-element': item.FE_ID == showItems[0].FE_ID && !hasShowed}"
              :data-background-image="index === 0 ? (item?.image?.src ?? '') : null"
              :style="{
                backgroundImage: `url(${ item.image.src })`,
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: `10rem auto`,
                backgroundPosition: `-8px -${item.topBgHeight + commonHeight?.moveUpHeight}rem`,
                overflow: 'hidden',
                zIndex: 2,
                position: 'absolute',
              }"
            >
            </div>
            
            <!-- 自动聚合 -->
            <LazyHydrationWrapper :when-visible="true">
              <ImmersiveProduct
                v-if="Number(item.bannerType) === 2"
                :class="{'not-fsp-element': hasShowed}"
                :item="item"
                :index="index"
                :positionTypeMap="positionTypeMap"
                :data-key="`${index}_${positionTypeMap[`${item.positionType}`][0]}_0`"
                :textBtnColor="textBtnColor"
                :cssRight="cssRight"
                :item-config="productItemConfig"
                :isR56="isH1R56"
                :clickProduct="clickProduct"
                :analysisCateItem="analysisCateItem"
                :bannerIns="bannerIns"
              />
            </LazyHydrationWrapper>
            <LazyHydrationWrapper :when-visible="true">
              <ImmersiveNewProduct
                v-if="newBannerType.includes(Number(item.bannerType))"
                :class="{'not-fsp-element': hasShowed}"
                :item="item"
                :index="index"
                :bannerType="Number(item.bannerType)"
                :cssRight="cssRight"
                :item-config="productItemConfig"
                :isR56="isH1R56"
                :clickProduct="clickProduct"
                :analysisCateItem="analysisCateItem"
                :bannerIns="bannerIns"
              />
            </LazyHydrationWrapper>
            <template v-if="item?.bannerImage?.src">
              <img
                v-if="index === 0"
                :src="item?.bannerImage?.src"
                class="gif-banner not-fsp-element"
                no-format
              />
              <img
                v-else
                :data-src="item?.bannerImage?.src"
                class="gif-banner not-fsp-element lazyload"
                data-exp="useWebp=0"
              />
            </template>
            <BaseImg
              v-if="item?.topBannerImage?.src"
              :first-screen="true"
              :is-support-webp="true"
              :img-design-width="359 * 3" 
              :img-src="item?.topBannerImage?.src"
              position="left top"
              fit="cover"
              class="c-immersiveBanner__bgLayer"
              :style="cutImgStyle(item)"              
            />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script>
// common
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { mapGetters, mapMutations, mapState } from 'vuex'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { transfromAndCutImg } from 'public/src/services/resource'
import expose from 'public/src/services/expose/index.js'
// components
import { LazyHydrationWrapper } from '@shein-aidc/utils-lazy-hydration'
import ImmersiveProduct from './ImmersiveProduct.vue'
import ImmersiveNewProduct from './ImmersiveNewProduct.vue'
import ImmersiveStaticBg from './ImmersiveStaticBg.vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

import { handleColor, hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { register } from 'swiper/element'
import { Autoplay, Pagination } from 'swiper/modules'
typeof window !== 'undefined' && register()

const { GB_cssRight, PUBLIC_CDN } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
let rem = null
// TODOS: 确认沉静式组件都是直出组件
export default {
  name: 'ImmersiveBanner',
  components: {
    ImmersiveProduct,
    ImmersiveStaticBg,
    BaseImg,
    ImmersiveNewProduct,
    LazyHydrationWrapper
  },
  mixins: [mixins],
  props: commonProps,
  inject: ['status'],
  data() {
    const cssRight = this.context?.GB_cssRight || false
    return {
      GB_cssRight,
      cssRight,
      loadComplete: false,
      showItems: [],
      swiperDir: 'ltr', // 设置swiper方向
      realSwiperInstance: null,
      realBannerSwiperRef: null,
      observerInstance: null,
      inViewport: false, // 是否在视口内
      initialTopOffset: 0,
      realSwiperBackTop: true, // 真实轮播回到了顶部，需要将header+tab置为透明色
      positionTypeMap: {
        '1': ['text', 'p1', 'p2'],
        '2': ['p1', 'p2', 'text'],
        '3': ['p1', 'text', 'p2']
      }, // 展示位置（1: 文字在左，商品在右, 2: 文字在右，商品在左, 3: 文字居中，商品两侧）
      enteredCreated: false, // 已经进入过了created钩子
      isMounted: false,
      immersiveBannerList: {},
      resizeObserver: null, // 屏幕尺寸发生变化时监听
      activeIndex: 0,
      lastHtmlFontSize: 0,
      isListRecommendMainImageReplace: false,
      headerShowHomePageBg: false, // 当滚动头部吸顶时候背景色是否展示首页的背景色（目前有沉浸式吸顶永远都是白色）
      newBannerType: [3, 4],
      productItemConfig: {
        disableMainimgJump: true,
        showCamelPrice: false,
        showSheinClubPriceOnSale: false,
        noNeedAnyEstimatedPriceTag: true, // 不需要任何到手价标签
        showNewFlashNewLabel: false,    // 不展示新型闪购文案标签
        showSheinClubNewLabel: false,  // 不展示付费会员文案标签
      },
      bannerIns: this,
      isPreviewPage: false,
      hasShowed: false,   // 是否展示过
    }
  },
  computed: {
    ...mapState(['commonHeaderBg', 'commonHeight']),
    // ...mapState('config_index', ['isH1R56']),
    ...mapGetters('config_index', ['topTabList', 'channelId', 'hasPolicyBanner']),
    isSecondaryHome() {
      return this.$route?.name === 'secondary_home'
    },
    curTab() {
      return this.topTabList?.length ? this.topTabList.find(i => i.channelId === this.channelId) : null
    },
    isShowComp() {
      return this.showItems.length
    },
    isShowCompAndLoadComplete() {
      return this.isShowComp && this.loadComplete
    },
    isShowPlaceholder() {
      return this.immersiveBannerItems?.length && !this.isShowComp
    },
    isH1R56() {
      return this.context?.abtInfoObj?.H1PictureProcess === 'H1_picture'
    },
    immersiveBannerItems() {
      const items = this.propData?.props?.items || []
      // 出现了未配置图片的沉浸式组件，导致出现服务端渲染的问题
      if (items.find(n => (n.image === null && n.backgroundImage === null))) {
        return []
      }
      const { isSupportWebp } = this.propData?.props || {}
      const { HomeDiscount: homeDiscountNew } = this.context?.abtInfoObj || {}
      if (isSupportWebp) { // 说明已经在服务端转过了
        return items
      }
      
      items.forEach(item => {
        // 1.将所有img资源进行转换
        const bannerType = Number(item.bannerType)
        
        if (bannerType === 2 || this.newBannerType.includes(bannerType)) { // 商品聚合
          if (Boolean(+item.showBackgroundImage)) {
            item.image = item.backgroundImage
          }
          let productList

          if (item?.productsV2) {
            productList = item.productsV2 || []
            // 同时删掉旧数据
            delete item.product_data
          } else {
            // 兜底
            productList = item.product_data?.products ?? []
          }

          productList.forEach(product => {
            product.goods_img = product.goods_img ? this.cutImg(product.goods_img) : product.goods_img
          })
          item.productList = productList
          if (item.themeColor) {
            item.bannerBgColor = `linear-gradient(to bottom, ${hexToRGBA(item.themeColor, 0.65)} 0%, ${hexToRGBA(item.themeColor, .90)} 100%)`
          }
        }

        if (item?.image?.src) {
          item.image.src = this.cutImg(item.image.src)
        }
        

        // UED给定，小于此参考比例为新素材，新素材就上移1.44rem(54 * 2 / 75), 旧素材就上移1.17rem(44 * 2 / 75)
        const referenceRatio = (1500 / 1090).toFixed(2)
        item.FE_newImg = +item.image.ratio < referenceRatio
        // 不处理gif banner，因为gif转webp以后，在safari浏览器会闪动
        item.homeDiscountNew = homeDiscountNew
      })
      return items
    },
    isCurrentChannelInstance() {
      return Number(this.channelId) === Number(this.context?.channelId) || this.isSecondaryHome
    },
    needSwiper() {
      return Array.isArray(this.showItems) && this.showItems?.length > 1
    },
    disableBottomSpacing() {
      return this?.metaData?.disableBottomSpacing
    },
    globalBgColor() {
      const backgroundColor = this.context?.extension?.backgroundColor
      return (backgroundColor && hexToRGBA(handleColor(backgroundColor))) || ''
    },
    globalBgImg() {
      return this.context?.extension?.backgroundImgSrc?.src
    },
  },
  watch: {
    isShowCompAndLoadComplete(val) {
      if (val) {
        this.$nextTick(() => {
          this.initRealBannerSwiper()
        })
      }
    },
    status: {
      deep: true,
      handler(val) {
        if (!val) {
          this.setHeaderTabTransparent(true)
        } else {
          this.setHeaderTabTransparent(false)
        }
      }
    },
    immersiveBannerItems: {
      handler(val) {
        this.$set(this.immersiveBannerList, this.channelId, val)
      },
      immediate: true,
      deep: true,
    },
    realSwiperBackTop: {
      handler(flag) {
        if (typeof window === 'undefined') {
          return
        }
        
        if (flag) {
          // 设置
          this.setHeaderTabTransparent(true)
          this.handleEnterViewport()
        } else {
          // 还原
          this.setHeaderTabTransparent(false)
          this.handleLeaveViewport()
        }
      }
    },
    channelId() {
      if (!this.isCurrentChannelInstance) {
        return
      }
      if (!this.status.loading) {
        this.isCurrentChannelInstance && this.setHeaderTabTransparent(true)
      } else {
        this.setHeaderTabTransparent(false)
      }
      this.transitionEnd()
      const firstNode = this.showItems[0]
      this.$nextTick(() => {
        this.changeHomeImmersiveInfo({
          commonHeight: {
            topTabHeight: firstNode?.topTabHeight,
            topBgHeight: firstNode?.topBgHeight,
            moveUpHeight: firstNode?.moveUpHeight,
            bottomBgHeight: firstNode?.bottomBgHeight,
            realBannerHeight: firstNode?.realBannerHeight,
          }
        })
      })
      
      this.onScroll()
      this.realSwiperBackTop = true
      this.addListener()
     
      this.clearResizeListener()
      this.addResizeListener()
      
    },
    $route() {
      if (!this.hasShowed && !this.isCurrentChannelInstance) {
        this.hasShowed = true
      }
    }
  },
  created() {
    // 服务端客户端都计算
    this.handleInitSwiperData() 
  
    this.enteredCreated = true
  },
  async mounted() {
    // 获取当前根元素的font-size
    rem = parseFloat(getComputedStyle(document.documentElement).fontSize) ?? 16
    this.isPreviewPage = !!this.$route.query?.contentId
    this.lastHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
    this.handleImmersiveProductSls()
    // 从全局abt 获取
    const { HomePageToppingBG } = this.context?.abtInfoObj || {}
    // const { HomePageTerminal } = await getUserAbtData()
    // const { param } = HomePageTerminal || {}
    this.headerShowHomePageBg = HomePageToppingBG == 'background'
  },
  activated() {
    if (!this.isCurrentChannelInstance && !this.isSecondaryHome) {
      return
    }
    if (this.enteredCreated && this.isShowComp && this.loadComplete && this.realSwiperBackTop) {
      this.setHeaderTabTransparent(true)
      const firstNode = this.showItems[0]
      this.$nextTick(() => {
        this.changeHomeImmersiveInfo({
          commonHeight: {
            topTabHeight: firstNode?.topTabHeight,
            topBgHeight: firstNode?.topBgHeight,
            moveUpHeight: firstNode?.moveUpHeight,
            bottomBgHeight: firstNode?.bottomBgHeight,
            realBannerHeight: firstNode?.realBannerHeight,
          }
        })
      })
    } else if (this.enteredCreated && this.isShowComp && this.loadComplete) {
      this.setHeaderTabTransparent(false)
    }
    
    
    if (!this.enteredCreated) {
      this.enteredCreated = true
    }
    this.$nextTick(() => {
      this.onScroll()
      this.addListener()
      this.lastHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
      this.addResizeListener()
      if (!this.realSwiperBackTop) {
        this.setHeaderTabTransparent(false)
      } else {
        this.setHeaderTabTransparent(true)
      }
    })
  },
  deactivated() {
    // 从二级首页离开时，还原header+tab
    if (this.isSecondaryHome) {
      this.setHeaderTabTransparent(false)
    }
    if (!this.isCurrentChannelInstance) {
      return
    }
    if (!this.hasShowed) {
      this.hasShowed = true
    }
    this.clearListenner()
    this.clearResizeListener()
  },
  beforeDestroy() {
    // 销毁 swiper 实例 和 observer 实例
    this.realSwiperInstance?.destroy()
    delete this.realSwiperInstance

    this.observerInstance?.destroy?.()
    this.observerInstance = null
  },
  destroyed() {
    this.clearListenner()
    this.clearResizeListener()
  },
  methods: {
    ...mapMutations(['changeHomeImmersiveInfo', 'changeRootStatus']),
    initRealBannerSwiper() {
      // swiper element
      const swiperEl = this.$refs.realBannerSwiper
      if (!swiperEl) return
      const paginationOption = this.needSwiper ? {
        pagination: {
          enabled: true,
        },
        injectStylesUrls: [
          PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css',
        ],
        injectStyles: [
          `
          .swiper-pagination{
            line-height: 0;
            bottom: 0 !important;
          }
          .swiper-pagination-bullets {
            transform: scale(0.5) translate3d(0,0,0) !important;
            -webkit-transform: scale(0.5) translate3d(0,0,0) !important;
          }
          .swiper-pagination .swiper-pagination-bullet{
            background: #fff;
            border: 1px solid #bbb;
            margin: 0 2px !important;
          }
          .swiper-pagination .swiper-pagination-bullet-active{
            background: rgba(255,255,255,0.8);
            border: 1px solid #bbb;
            width: 0.5133333rem;
            height: 0.193333rem;
            border-radius: 0.21333333rem;
          }
        `,
        ],
      } : {}
      
      // swiper parameters
      const swiperParams = {
        direction: 'horizontal',
        loop: this.immersiveBannerItems?.length > 1,
        modules: [Autoplay, Pagination],
        autoplay: {
          delay: 5 * 1000,
          disableOnInteraction: false
        },
        resizeObserver: true,
        spaceBetween: 0,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        autoplayOutsideControl: true,
        ...paginationOption,
      }
      
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      
      this.handleRealSwiperInit(swiperEl?.swiper)
      this.initRealSwiperObserver(swiperEl, swiperEl?.swiper)
    },
    initRealSwiperObserver(swiperEl, swiperInstance) {
      this.observerInstance = new expose({ observeHide: true })
      this.observerInstance.observe({
        elements: swiperEl,
        once: false,
      }, ({ exposeDoms }) => {
        if (!swiperInstance) return

        if (exposeDoms.length) {
          // 进入视口
          this.inViewport = true
          this.playSwiperAutoplay()
        } else {
          // 离开视口
          this.handleLeaveViewport()
        }
      })
    },
    cutImgStyle(item) {
      // 判断图层是否需要裁剪, 手动消除裁剪差异，设置图片宽高往右下移动1px
      const defaultWidth = 359 * 3
      const defaultHeight = 138 * 3
      const isCut = item?.topBannerImage?.width > defaultWidth || item?.topBannerImage?.height > defaultHeight
      if (isCut) {
        return {
          width: `calc(${item?.topBannerImage?.width / 3 / 37.5 + 'rem'} + 1px)`,
          height: `calc(3.68rem - 1px)`,
        }
      }
      return {}
    },
    cutImg(imgUrl, forceTransform) {
      const { RESOURCE_SDK } = this.context
      const { deviceData = '', isSupportWeb = '', sceneMap = {}, isSupprotCut = '' } = RESOURCE_SDK || {}
      if (!isSupportWeb) return imgUrl
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : isSupprotCut,
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950',
      }
      return transfromAndCutImg(cutData)
    },
    addListener() {
      window.addEventListener('scroll', this.onScroll)
    },
    clearListenner() {
      window.removeEventListener('scroll', this.onScroll)
    },
    resizeHandler() {
      this.loadComplete = false
      window.requestAnimationFrame(() => {
        this.transfromImgsLink(true)
      })
    }, 
    addResizeListener() {
      if (this.resizeObserver) {
        this.resizeObserver?.disconnect()
      }

      const config = {
        attributes: true,
        attributeFilter: ['style']
      }
      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          const currentHtmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) || 0
          if (mutation.type === 'attributes' && mutation.attributeName === 'style' && currentHtmlFontSize !== this.lastHtmlFontSize) {
            this.resizeHandler()
            this.lastHtmlFontSize = currentHtmlFontSize
            // 屏幕尺寸发生变化后会重新初始化 realswiper，回到第一帧，背景图也需要回到第一帧
            this.activeIndex = 0 
          }
        }
      }

      this.resizeObserver = new MutationObserver(callback)
      this.resizeObserver?.observe(document.documentElement, config)
    },
    clearResizeListener() {
      this.resizeObserver?.disconnect()
    },
    textBtnColor(item) {
      const COLOR = {
        '1': item.themeColor,
        '2': '#000',
        '3': '#fff'
      }
      if (item.bgFillType === 1) {
        return COLOR[`${item.fillTextColor}`]
      } else {
        return item?.textColor
      }
    },
    handleInitSwiperData() {
      if (!this.content?.length) return
      if (!this.immersiveBannerItems?.length) {
        this.resetNoData()
        return
      }
      this.transfromImgsLink()
    },
    resetNoData() {
      this.showItems = []
      this.loadComplete = false
      if (Object.keys(this.commonHeaderBg).length) {
        this.setHeaderTabTransparent(false)
      }
    },
    // 初始化数据
    transfromImgsLink(isResize = false) {
      try {
        const firstConfig = this.immersiveBannerList?.[this.channelId]?.[0] || this.immersiveBannerItems[0]
       
        // 计算各类高度值
        // 如果存在高度，不需要执行
        if (this.commonHeight?.bottomBgHeight && this.loadComplete && !isResize) return

        const htmlFontSize = 1
        const headerHeight = 1.17 * htmlFontSize
        const tabHeight = 1.17 * htmlFontSize
        
        // header头部 + tab高度
        const topBgHeight = headerHeight + tabHeight
        // header头部
        const topHeaderHeight = headerHeight
        // tab高度
        const topTabHeight = tabHeight
        // 设置初始化高度
        this.initialTopOffset = !this.isSecondaryHome ? topBgHeight : headerHeight
        // 以第一个配置项为基准
        const imageInfo = firstConfig?.image || {} // {height,width,src,ratio}
        // const ratio = ((imageInfo?.width / 10) / htmlFontSize) || 1 
      
        if (!imageInfo.height) {
          // banner最小高度 UED设计0.6比例
          imageInfo.height = 10 * htmlFontSize * 0.6
        }
        // 根据新旧素材判断上移具体数值
        const moveUpMap = {
          'new': 1.18 * htmlFontSize,
          'old': 1 * htmlFontSize,
        }
        let moveUpHeight = firstConfig.FE_newImg ? (moveUpMap.new) : (moveUpMap.old)
        let realBannerHeight = imageInfo?.height * 10 / imageInfo?.width || 1
        let bottomBgHeight = realBannerHeight - topBgHeight
        // UED设计1.1比例
        const max_bannerHeight = 10 * htmlFontSize * 1.1
        if (realBannerHeight > max_bannerHeight) {
          // banner最大高度 
          // maxrealBannerHeight = max_bannerHeight
          realBannerHeight = max_bannerHeight
          bottomBgHeight = realBannerHeight - topBgHeight
          
        }
        const showItems = [...this.immersiveBannerItems]
        showItems.forEach((item, index) => {
          item.FE_ID = `${index}_${Math.random().toString().substring(2, 10)}`
          item.topTabHeight = topTabHeight
          item.topBgHeight = topBgHeight
          item.moveUpHeight = moveUpHeight
          item.bottomBgHeight = bottomBgHeight
          item.realBannerHeight = realBannerHeight
          if (item.productList?.length > 2) {
            // 取前两条数据
            item.productList = item.productList.slice(0, 2)
          }
        })
        for (const key in this.propData?.immersiveBanner?.product) {
          if (Object.hasOwnProperty.call(this.propData?.immersiveBanner?.product, key)) {
            const element = this.propData?.immersiveBanner?.product[key]
            if ( element.interfaceSource === 'listRecommend' && element.isMainImageReplace) {
              // 白底透明图，添加背景色
              this.isListRecommendMainImageReplace = true
              break
            }
          }
        }
        this.showItems = showItems
        // 异常日志上报
        if(!this.showItems.length) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'CCCShownFilterError',
            reason: 'typeError',
          })
        }
        // 是否SSR
        if (typeof window !== 'undefined') {
          this.$nextTick(() => {
            if(this.isCurrentChannelInstance && !isResize){
              this.changeHomeImmersiveInfoByEnv({
                topBgHeight,
                moveUpHeight,
                topHeaderHeight,
                topTabHeight,
                bottomBgHeight,
                realBannerHeight,
                isResize
              })
            }
          })
        } else {
          this.changeHomeImmersiveInfoByEnv({
            topBgHeight,
            moveUpHeight,
            topHeaderHeight,
            topTabHeight,
            bottomBgHeight,
            realBannerHeight,
            isResize
          })
        }

        this.loadComplete = true
        this.playSwiperAutoplay()
      } catch (error) {
        console.log('home-immersive-banner fail.', error)
      }
    },
    changeHomeImmersiveInfoByEnv( { topBgHeight, moveUpHeight, topHeaderHeight, topTabHeight, bottomBgHeight, realBannerHeight }) {
      this.changeHomeImmersiveInfo({
        commonHeaderBg: {
          showBlock: true, // 沉浸式开关
          homeTabStatus: true,
          topTextColor: '#ffffff', // 目前可写死 自动聚合、清晰 => #ffffff
          immersiveFlag: true,
          backgroundColor: this.globalBgColor
        }
      })
      this.changeHomeImmersiveInfo({
        commonHeight: {
          topBgHeight,
          moveUpHeight,
          topHeaderHeight,
          topTabHeight,
          bottomBgHeight,
          realBannerHeight,
        }
      })
    },

    handleRealSwiperInit(swiper) {
      this.realSwiperInstance = swiper
      if (this.needSwiper && this.loadComplete && this.isShowComp) {
        this.$nextTick(() => {
          typeof observer !== 'undefined' && observer.observe()
          this.swiperLoopAutoplyToNext(swiper)
        })
      }
      this.addListener()
      this.addResizeListener()
    },
    clickProduct(params) {
      const goodsId = params.goods_id ?? ''
      this.handleSlideClick(goodsId)
    },
    // 处理swiper为loop时 事件绑定的问题
    handleSlideClick(goodsId) {
      const realIndex = this.getSwiperRealIndex()
      let item = this.showItems[realIndex]
      let goods_ids = item?.productList?.map(_i => _i.goods_id) || []
      if (goodsId && typeof goodsId === 'string') {
        goods_ids = [goodsId, ...goods_ids.filter(n => n !== goodsId)]
      }
      let goods
      if (goods_ids && Array.isArray(goods_ids) && goods_ids.length) {
        goods = goods_ids.join(',')
        // item.adp ? (item.adp = `${goods},${item.adp}`) : (item.adp = `${goods}`)
      }
      let url = this.cccLink.getFullLink({
        item: !goods_ids.length ? item : { ...item, adp: `${goods}` },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        isBff: true,
        index: (realIndex + 1)
      })
      this.jumpLink({ url, hrefType: item.hrefType })
    },
    onScroll() {
      if (!this.isCurrentChannelInstance && !this.isSecondaryHome) return
      let currentTopOffset = this.$refs.realBannerSwiper?.getBoundingClientRect().top || 0
      
      if (currentTopOffset < this.initialTopOffset * rem - 4) {
        if (this.isPreviewPage) {
          // 此处用于修复预览页免邮券弹框带来的顶部样式异常问题，是否有更好的方式来实现这个判断
          if (!!document.querySelector('.free-shipping__sticky-block')) {
            this.realSwiperBackTop = false
            return
          }
          this.realSwiperBackTop = window.scrollY <= 5
        } else {
          this.realSwiperBackTop = false
        }
        
      } else if (currentTopOffset >= this.initialTopOffset * rem - 2) {
        this.realSwiperBackTop = true
      }
    },
    /**
     * header + tab 设置为透明色
     * @param true  header + tab 设置为透明色
     * @param false header + tab 还原成本色
     */
    setHeaderTabTransparent(flag) {
      if(!this.isCurrentChannelInstance){
        return
      }
      if (flag) {
        this.$nextTick(() => {
          this.changeHomeImmersiveInfo({
            commonHeaderBg: {
              showBlock: true, // 沉浸式开关
              homeTabStatus: true,
              topTextColor: '#ffffff', // 目前可写死 自动聚合、清晰 => #ffffff
              immersiveFlag: true,
              backgroundColor: this.globalBgColor,
              bgImgSrc: '',
              stickyTop: true,
            }
          })
        })
      } else {
        this.$nextTick(() => {
          const changeCommonHeaderBgInfo = this.headerShowHomePageBg ? {
            topTextColor: this.context?.extension?.topTextColor, // 传入自定义颜色
            bgImgSrc: this.globalBgImg,
            showBlock: Boolean(this.globalBgImg),
            homeTabStatus: Boolean(this.globalBgImg),
          } : {
            showBlock: false,
            homeTabStatus: false,
          }
          this.changeHomeImmersiveInfo({
            commonHeaderBg: {
              backgroundColor: this.globalBgColor,
              stickyTop: false,
              ...changeCommonHeaderBgInfo,
            }
          })
        })
      }
    },
    getSwiperRealIndex() {
      const el = this.$refs.realBannerSwiper
      const activeSlideEl = el?.querySelector('.swiper-slide-active') || null
      const eleRealIndex = activeSlideEl?.getAttribute('data-realswiperIndex') || 0
      return Number(eleRealIndex)
    },
    transitionEnd() {
      const realIndex = this.getSwiperRealIndex()
      this.activeIndex = realIndex
    },
    // 进入视口时，恢复自动轮播
    handleEnterViewport() {
      this.inViewport = true
      if (this.needSwiper && this.realSwiperInstance && !this.realSwiperInstance?.autoplay?.running) {
        this.swiperLoopAutoplyToNext(this.realSwiperInstance, true)
      }
      this.transitionEnd()
    },
    // 离开视口时停止自动轮播
    handleLeaveViewport() {
      this.inViewport = false
      this.pauseSwiperAutoplay()
    },
    playSwiperAutoplay() {
      // this.pauseSwiperAutoplay()
      // 折叠屏翻转时，realSwiperBackTop 可能为 false，这种情况应该暂停自动轮播
      if (!this.realSwiperBackTop) {
        this.pauseSwiperAutoplay()
        return
      }
      if (this.inViewport && !this.realSwiperInstance?.autoplay?.running) {
        this.realSwiperInstance?.autoplay?.start?.()
      }
    },
    pauseSwiperAutoplay() {
      this.realSwiperInstance?.autoplay?.stop?.()
    },
    swiperLoopAutoplyToNext(swiper) {
      this.realSwiperInstance = swiper
      this.playSwiperAutoplay()
    },
    // 埋点
    analysisCateItem(item) {
      const params = {
        ...item,
        DeepBannertype: item.bannerType
      }
      const productData = item.productList || []
      if ([2, 3, 4].includes(+item.bannerType) && Array.isArray(productData) && productData.length) {
        let goods_id = productData.map(_i => _i.goods_id)
        goods_id = goods_id.join('|')
        params.goods_to_list = goods_id
      }
      return params
    },
    // 自动聚合日志监控
    handleImmersiveProductSls() {
      if(this.showItems.length == 0) {
        this.handleHideCompSls('CCCShownFilterError', 'itemError')
      }

      this.showItems.forEach(item => {
        if(!item?.image?.src || !item?.image?.height || !item?.image?.width) {
          this.handleHideCompSls('CCCDataError', 'itemImageError')
        }

        if(![1, 2].includes(Number(item.bannerType))) {
          this.handleHideCompSls('CCCDataError', 'typeError')
        }

        if(+item?.bannerType === 2 && item?.productList?.length < 2) {
          this.handleHideCompSls('CCCDataError', 'goodsNumError')
        }
      })
    },
    handleHideCompSls(message, reason) {
      this.hideCompSls({
        logLevel: 3,
        tag: 'web_client_home_error_total',
        message,
        reason,
      })
    }
  }
}
</script>

<style lang="less">
.gif-banner {
  position: absolute;
  width: 100%;
  z-index: @zindex-out;
  top: 50%;
  transform: translateY(-50%);
}

.c-immersiveBanner__trans-placeholder {
  position: absolute;
  z-index: 11;
  width: 100%;
  bottom: -1px;
  height: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #fff;
}

/* stylelint-disable */
@vw: 375/100vw;
.c-immersiveBanner {
  &__area {
    overflow: hidden;
  }
  
  &__area.has-bottom-space {
    margin-bottom: 4 / @vw;
  }
  
  &__static-swiper {
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
  }
  
  &__static-slide {
    transform: translate3d(0, 0, 0);
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }
  
  &__real { // 真实轮播区域
    position: absolute;
    z-index: 10;
  }
  
  &__real-swiper {
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  &__real-slide {
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }
  &__list-recommend-out .c-immersiveBanner__product .list-style1__base-avatar {
    background: #ffffff;
  }
  &__wrap {
    background-color: #fff;
    position: relative;
  }
  
  &__swiper {
    transform: translate(0, 0, 1);
    backface-visibility: hidden;
    box-sizing: border-box;
    border-radius: 6px;
  }
  
  &__slide {
    width: 100%;
    position: relative;
    overflow: hidden;
    //background-color: #fff;
  }
  
  &__bgImage {
    width: 10rem;
    z-index: 1;
    background-repeat: no-repeat; /* 垂直方向无缝重复 */
    background-size: 10rem auto; /* 设置背景图片宽度为100%，高度自适应 */
    // transform: translate3d(0, 0, 0);
  }
  &__bgLayer {
    position: absolute;
    width: 100%;
    height: 3.68rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }
  
  &__products {
    .flexbox();
    .align-center();
    .pack-center();
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    color: #bbb;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 40/75rem;
    font-weight: 600;
    padding: 0 16/75rem;
    padding-top: .3493rem;
    z-index: @zindex-select;
  }
  
  &__text {
    padding: 0 20/75rem;
    .flexbox();
    .align-center();
    .pack-center();
    flex-direction: column;
    color: #fff;
    text-align: center;
    flex: 1;
    max-width: 50%;
    
    .sub {
      .line-camp(2);
      font-family: Helvetica, Arial, sans-serif;
      font-size: 38/75rem;
      font-weight: 900;
      margin-bottom: 20/75rem;
      -webkit-text-stroke: 1px var(--sub-font-color);
    }
    
    .mainTitle {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 26/75rem;
      font-weight: 400;
      margin-top: 0.08rem;
      margin-bottom: 12/75rem;
    }
    
    .btn, .swiper-pagination-bullet {
      .flexbox();
      .align-center();
      .pack-center();
      // max-width: 200%;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 38/75rem;
      font-weight: 600;
      border: 4px solid #fff;
      margin-bottom: 8/75rem;
      padding: 4/75rem 8/75rem 4/75rem 24/75rem;
      vertical-align: middle;
      border-radius: 4/75rem;
      transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform-origin: top center;
    }
  }
  
  &__product {
    width: 2.1867rem;
    height: 2.896rem;
    position: relative;
    //flex-shrink: 0;
    
    &.needMr {
      margin-right: 12/75rem;
    }
    
    &.isR56{
      width: 164 / 75rem;
      height: 196 / 75rem;
    }

    .list-style1__base-avatar {
      object-fit: cover;
      width: 100%;
      height: 100%;
      .border-dpr(border, 2px, rgba(255, 255, 255, 0.6));
      border-radius: 2px;
    }
    .c-immersiveBanner__price-color-black .bff-price-container .prices-info__sale-price {
      color: #222;
    }
    .bff-price-container {
      position: absolute;
      bottom: 8px;
      .flexbox();
      .align-center();
      .pack-center();
      text-align: center;
      width: 100%;
      .c-immersiveBanner__price-red {
        color: #FA6338;
      }
      
      .price-overflow-hidden {
        overflow: unset;
      }
      .product-card__prices {
        max-height: unset;
      }
      .prices-info__sale-price {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        text-align: center;
        border-radius: 32/75rem;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 24/75rem;
        line-height: 32/75rem;
        font-weight: 600;
        min-height: 32/75rem;
        padding: 5/75rem 16/75rem;
        background-color: rgba(255, 255, 255, 0.8);
        //color: #222222;
      }
    }
  }
  
  &__placeholder {
    &.has-bottom-space {
      margin-bottom: 4 / @vw;
    }
    
    .content {
      position: relative;
      width: 100%;
      
      .placeholder-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
  .base-img__inner{
    border-radius: 2px;
  }
  
}

.c-immersiveBanner__wrap {
  /deep/ .swiper-wrapper {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }
}


.bg-index {
  width: 100%;
  height: 100%;
  background-color: aqua;
}
</style>
