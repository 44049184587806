<template>
  <div
    :class="[className, 'shop-entry__storeFollowBtnBox']"
    @click.stop="onClick({ storeCode: storeId })"
  >
    <slot name="button"></slot>
    <!-- follow success popup dialog -->
    <SDialog
      :visible.sync="popupShow"
      show-close
      append-to-body
    >
      <template slot="title">
        {{ language?.followTitleLang || langSource.SHEIN_KEY_PWA_24070 }}
      </template>
      <div class="shop-entry__storeFollowTipsContentBox">
        <p>
          {{ language?.followDescriptionLang || langSource.SHEIN_KEY_PWA_24071 }}
        </p>
        <img :src="`${PUBLIC_CDN}/pwa_dist/images/store/follow-store-tips-903cc3c5df.png`" />
        <div class="shop-entry__modalBtnBox">
          <SButton
            height="36px"
            width="100%"
            :type="['primary']"
            @click="() => (popupShow = false)"
          >
            {{ language?.okLang || langSource.SHEIN_KEY_PWA_24059 }}
          </SButton>
        </div>
      </div>
    </SDialog>
    <Transition name="promptBox">
      <div
        v-if="showPromptFollow"
        v-expose="{
          id: '2-22-20',
          data: {
            item: analyData,
          },
        }"
        v-tap="{
          id: '2-22-21',
          data: {
            item: analyData,
          },
        }"
        :class="{
          promptBox: true,
          noBrandShop: !isShowBranchShop
        }"
        @click.stop="onClick({ storeCode: storeId })"
      >
        <div class="content">
          <div
            v-if="shopData.logo"
            class="header-logo"
          >
            <img
              ref="logo"
              class="lazyload"
              :data-src="shopData.logo.replace(/^https?:/, '')"
              aria-hidden="true"
            />
          </div>
          <p 
            class="text"
            v-html="promptBoxText"
          ></p>
          <button class="follow_btn">
            <Icon 
              name="sui_icon_add_12px" 
              size="12px"
            />
            {{ language.followLang }}
          </button>
        </div>
      </div>
    </Transition>
    <Transition name="toastBox">
      <div
        v-if="showPromptToast"
        class="toastBox toastBox1"
      >
        {{ language?.followTitleLang }}
      </div>
    </Transition>
    <!-- <div class="toastBox toastBox2">{{ language?.followTitleLang }}</div> -->
  </div>
</template>

<script>
/**
 * @component StoreFollowBtnBox 店铺关注按钮外壳
 * * 处理店铺关注和取消关注逻辑，无vuex依赖
 */
import schttp from 'public/src/services/schttp'
import { Dialog as SDialog, Button as SButton, Toast as $toast } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-63-0' })

const { language: commonLang, PUBLIC_CDN } = gbCommonInfo
const NEW_SCENE_KEY_MAP = new Map([
  ['StoreDetail', 'store'],
  ['ProductDetail', 'detail']
])
// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

// 浏览10s后展示关注提示条
const VIEW_TIME = 10000

export default {
  name: 'StoreFollowBtnWrap',
  components: {
    SDialog,
    SButton,
    Icon
  },
  props: {
    // 盒子类名
    className: {
      type: String,
      default: ''
    },
    // 场景
    // 店铺页: StoreDetail 商品详情页: ProductDetail
    scene: {
      type: String,
      default: ''
    },
    // 店铺关注状态
    followState: {
      type: Boolean,
      default: false
    },
    // 店铺id
    storeId: {
      type: [String, Number],
      default: ''
    },
    // 未登录时是否自动登录
    autoLogin: {
      type: Boolean,
      default: false
    },
    // 多语言
    language: {
      type: Object,
      default: () => ({})
    },
    shopData: {
      type: Object,
      default: () => ({})
    },
    storeAbtResult: {
      type: Object,
      default: () => ({})
    },
    hasCccfollowFloat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.isListening = true
    return {
      loading: false,
      popupShow: false,
      showPromptFollow: false, // 关注提示条
      showPromptToast: false, //关注成功提示
      beenPopupShow: false,
      timeId: null,
      PUBLIC_CDN
    }
  },
  computed: {
    analyData({ storeId }) {
      return {
        storeCode: storeId,
        followsStatus: 0,
        scene: 0,
      }
    },
    langSource() {
      const { language } = this
      return language || commonLang || {}
    },
    // 店招是否命中新样式（品牌店&&命中实验）
    isShowBranchShop () {
      return this.shopData?.storeBusinessType === 2
    },
    promptBoxText() {
      const mayWrapIcon = ({ text, iconUrl, fontColor }) => this.showPromotionAndNewArrivalIcon
        ? `<span style="display: inline-block;align-items: center;padding-left: ${16 / 37.5}rem;position: relative;color: ${fontColor}">
            <i style="position: absolute;left: 0;top: 0;width: ${14 / 37.5}rem;height: ${14 / 37.5}rem;background: url(${iconUrl}) 50% 50% / contain no-repeat;"></i>
            ${text}
          </span>`
        : text
      return template(
        mayWrapIcon({
          text: `<b>${this.language.SHEIN_KEY_PWA_31318}</b>`,
          iconUrl: 'https://img.ltwebstatic.com/images3_ccc/2024/08/19/b8/172405667376a768297834b02565dcbee6eadd07a8.png',
          fontColor: '#FF5139',
        }),
        mayWrapIcon({
          text: `<b>${this.language.SHEIN_KEY_PWA_31746}</b>`,
          iconUrl: 'https://img.ltwebstatic.com/images3_ccc/2024/08/19/42/1724056652cb430319988606c589f97b604ad6ab34.png',
          fontColor: '#3CBD45',
        }),
        this.language.SHEIN_KEY_PWA_31896 || 'Follow for the {0} and {1}'
      )
    },
    showPromotionAndNewArrivalIcon() {
      const isFashionStore = this.shopData?.trendsLabelInfo?.status == 1
      return isFashionStore && this.storeAbtResult.followenhance?.p?.popupFollowButton === 'B'
    },
  },
  mounted() {
    this.updateBeenPopupShow()
    if(this.hasCccfollowFloat){
      this.checkNewRulesPromptFollow()
    }
    this.initEvent()    
  },
  activated() {
    if(this.hasCccfollowFloat){
      this.checkNewRulesPromptFollow()
    }
    if (!this.isListening)
      this.initEvent()
  },
  deactivated() {
    clearTimeout(this.timeId)
    this.removeEvent()
  },
  beforeDestroy() {
    clearTimeout(this.timeId)
    this.removeEvent()
  },
  methods: {
    initEvent() {
      appEventCenter.$on('FlowStoreFolloingClickFollow', this.flowStoreFolloingClickFollow)
    },
    removeEvent() {
      appEventCenter.$off('FlowStoreFolloingClickFollow', this.flowStoreFolloingClickFollow)
      this.isListening = false
    },
    flowStoreFolloingClickFollow({ storeCode, scene }) {
      if (storeCode !== this.storeId) return
      this.onClick({ storeCode, scene })
    },
    // 24-06-12 新增时尚店铺场景关注提示策略宽松版
    checkNewRulesPromptFollow(){
      // https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1385049139
      // 关注条策略放宽
      // 店铺范围
      // 接入ccc 全局关注条配置
      // 请求ccc，根据CCC返回条件（CCC配置）展示策略，其余未返回店铺不展示提示条
      // 策略细节
      // 展示条件放宽：用户第一次进入店铺就展示提示条，浏览五秒后展示，5 秒后自动关闭
      // 单个店铺展示重复限制取消：如果用户在店铺内展示提示条时没有点击，用户再次进入店铺可再次展示，无限制
      // 单个店铺展示冷静取消：一个用户在同一个店铺内看到提示条的次数无限制
      // 多个店铺展示上限取消：每个用户每个24小时内看到的提示条无限制
      if (!this.storeId) return
      const { member_id } = gbCommonInfo?.user || {}
      const showFollowWithoutLogin = this.storeAbtResult.nologinfollow?.p?.nologinfollow === 'B'
      if(!member_id && !showFollowWithoutLogin){
        return
      }
      this.timeId = setTimeout(() => {
        // 有商详条的情况下不展示关注条
        const hasCateAdpTips = sessionStorage.getItem('STORE_CATE_ADP_TIPS_SHOW')
        if (hasCateAdpTips || this.followState) return
        this.showPromptFollow = true // 展示提示
        setTimeout(() => {
          this.showPromptFollow = false // 展示后5秒自动关闭
        }, 5000)
      }, VIEW_TIME)
    },
    // 点击关注按钮
    async onClick(followParams) {
      const { scene: followBtnScene } = followParams || {}
      const {
        loading,
        storeId,
        followState,
        updateBeenPopupShow,
        autoLogin,
        langSource
      } = this
      if (loading || !storeId) return

      this.loading = true
      this.$emit('state-changing', true)
      const targetState = !followState
      try {
        const { followState, storeId, scene } = this
        // 此处app中间层要求请求体格式为formData
        const formData = new FormData()
        formData.append('action', !followState ? 'follow' : 'unfollow')
        formData.append('scene', NEW_SCENE_KEY_MAP.get(scene))
        formData.append('store_code_list', storeId)
        const result = await schttp({
          url: '/product/store/update_following',
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
          useBffApi: true
        })
        const { code } = result
        if (code === '0') {
          this.$emit('state-changed', targetState)
          // 首次关注成功，则弹出关注成功弹窗
          updateBeenPopupShow()
          appEventCenter.$emit('store_pages:storeFollowStateChange', {
            storeCode: storeId,
            followState: targetState,
            isFirstTimeFollow: !this.beenPopupShow,
            scene: followBtnScene,
          })
          this.showPromptFollow = false // 关闭提示条
          if (targetState) {
            // 关注
            if (this.beenPopupShow) {
              // 已经提示过第一次
              this.$toast(this.language?.followTitleLang || '', 3000) //通用提示
            } else {
              // 第一次提示
              this.popupShow = true
              this.beenPopupShow = true
              window.localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
            }
          }
        } else if (String(code) === '00101110') {
          // 未登录
          if (autoLogin && typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
            const loginCallback = state => {
              if (String(state?.code) === '0') {
                appEventCenter?.$emit('store_pages:followStoreAfterLogin', followParams)
              }
            }
            SHEIN_LOGIN.show({
              show: true,
              bi: 'other',
              cb: loginCallback
            })
          } else {
            this.$emit('need-login')
          }
        } else {
          throw result
        }
      } catch (err) {
        const toastContent = targetState
          ? String(err?.code) === '400429'
            ? langSource.SHEIN_KEY_PWA_24077 // 关注失败，关注数量超出上限
            : langSource.SHEIN_KEY_PWA_24076 // 关注失败，请稍后重试
          : langSource.SHEIN_KEY_PWA_24075 // 取消关注失败，请稍后重试
        this.$nextTick(() => {
          $toast({
            content: toastContent
          })
        })
        this.$emit('state-change-fail', err)
      }
      this.loading = false
      this.$emit('state-changing', false)
    },
    // 更新是否展示过关注成功弹窗状态
    updateBeenPopupShow() {
      if (typeof window === 'undefined') return
      this.beenPopupShow = !!window.localStorage?.getItem(BEEN_POPUP_SHOW_KEY)
    }
  },
  emits: ['state-changed', 'state-changing', 'need-login', 'state-change-fail']
}
</script>

<style lang="less">
.shop-entry__storeFollowBtnBox {
  display: block;
}

.shop-entry__storeFollowTipsContentBox {
  width: 100%;
  text-align: center;

  p {
    margin-top: 0.32rem;
    color: @sui_color_gray_dark1;
    .font-dpr(28px);
  }

  img {
    display: block;
    width: 100%;
    margin-top: 0.32rem;
  }

  .shop-entry__modalBtnBox {
    margin-top: 0.746667rem;
    margin-bottom: 0.533333rem;
    justify-content: center;
  }
}
</style>
<style lang="less" scoped>
@keyframes animation1 {
  from {
    // transform: scale(0.5);
    // bottom: 100/74rem;
    opacity: 0;
  }
  to {
    // transform: scale(1);
    // bottom: 140/74rem;
    opacity: 1;
  }
}
@keyframes animation2 {
  from {
    // transform: scale(1);
    opacity: 1;
  }
  to {
    // transform: scale(0.5);
    opacity: 0;
  }
}
.promptBox_enter-active {
  animation: animation1 0.3s;
}
.promptBox_leave-to {
  animation: animation2 0.3s;
}
.promptBox {
  position: fixed;
  z-index: 99;
  bottom: 104/75rem;
  left: 40/75rem;
  right: 40/75rem;
  background: rgba(0, 0, 0, 0.8);

  &.noBrandShop {
    bottom: 52/75rem;
  }
  .content {
    padding: 24/75rem 20/75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-logo {
      border: 0.5px solid #e5e5e5;
      width: 60/75rem;
      height: 60/75rem;
      text-align: center;
      overflow: hidden;
      background-color: #fff;
      > img {
        height: 100%;
      }
    }
    .follow_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2 / @w-375rem;
      padding: 10/75rem 16/75rem;
      border: 0.5px solid rgba(255, 255, 255, 0.6);
      font-size: 24/75rem;
      line-height: 14 / @w-375rem;
      font-weight: 400;
      color: #000;
      background-color: #fff;
    }
    .text {
      flex: 1;
      max-height: 56/75rem;
      margin-left: 20/75rem;
      margin-right: 16/75rem;
      color: #fff;
      font-size: 24/75rem;
      font-weight: 400;
      line-height: 28/75rem;
      -webkit-line-clamp: 2; /* 设置最大显示行数 */
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@keyframes animation3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animation4 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.toastBox_enter-active {
  animation: animation3 0.3s;
}
.toastBox_leave-to {
  animation: animation4 0.3s;
}

.toastBox {
  padding: 24/75rem;
  max-width: 8rem;
  position: fixed;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  font-size: 28/75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32/75rem;
}

.toastBox1 {
  bottom: 2rem;
}
.toastBox2 {
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
