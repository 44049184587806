<template>
  <div 
    v-if="camelComponentsData.originString"
    class="bff-price-container"
    :class="containerClassName"
  >
    <div class="bff-price-container__top">
      <!-- 主价格 -->
      <CamelCasePrice
        v-if="goodsInfo.salePrice"
        :show-camel-price="camelComponentsData.showCamelPrice"
        :origin-string="camelComponentsData.originString"
        :camel-price-style="camelComponentsData.camelPriceStyle"
        :camel-price-resize-fontsize="camelComponentsData?.camelPriceResizeFontsize"
        :price="goodsInfo.salePrice"
        :currency-symbol-prefix="camelComponentsData.currencySymbolPrefix"
        :number-before-dot="camelComponentsData.numberBeforeDot"
        :number-after-dot="camelComponentsData.numberAfterDot"
        :currency-symbol-suffix="camelComponentsData.currencySymbolSuffix"
        :sale-price-style="salePriceStyle"
        class="prices-info__sale-price"
      />
      <!-- 折扣标签 -->
      <div 
        v-if="unitDiscountString" 
        class="discount-label"
      > 
        {{ unitDiscountString }} 
      </div>
      <!-- 原价&合规价 -->
      <SuggestedSalePrice 
        v-if="priceSuggested.priceRight.show"
        :value="priceSuggested.priceRight.price"
        :label-text="priceSuggested.priceRight.labelText"
        price-delete-style
      />
    </div> 
    <!-- 合规价 -->
    <SuggestedSalePrice 
      v-if="priceSuggested.priceBottom.show"
      :value="priceSuggested.priceBottom.price"
      :label-text="priceSuggested.priceBottom.labelText"
      :flip-text="priceSuggested.priceBottom.flipText"
      price-delete-style
    />
  </div>
</template>

<script name="HomeBffPrice" setup>
import { computed, toRefs } from 'vue'
import CamelCasePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/CamelCasePrice.vue'
import SuggestedSalePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/SuggestedSalePrice.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'
import { apolloSuggestedSaleConfig } from 'public/src/pages/components/product/item_v3/js/constant.js'
const { GB_cssRight, lang } = gbCommonInfo
const props = defineProps({
  /**
   * @param {Object} 商品信息
   */
  goodsInfo: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  /**
   * @param {String} 当前合规站点
   */
  suggestedSaleType: {
    type: String,
    default: ''
  },
  /**
   * @param {SceneConfig} 具体场景自定义配置项
   */
  config: {
    type: Object,
    default() {
      return {
        showCamelPrice: false,   // 驼峰价格
        camelPriceResizeFontsize: false,
        showDiscountLabelAfterPrice: false, // 价格后面显示折扣标签
        showRetailPrice: false, // 是否展示原价
        showSuggestedSalePrice: false, // 是否展示合规价
      }
    }
  },
  /**
   * @param {SceneConfig} bff返回驼峰字体大小
   */
  priceFontSize: {
    type: Object,
    default: () => {}
  },
})

const {config, goodsInfo, suggestedSaleType, priceFontSize } = toRefs(props)

// 价格组件所需数据转换
const camelComponentsData = computed(() => {
  const { salePrice } = goodsInfo.value
  const amountWithSymbol = salePrice?.amountWithSymbol || ''
  const { style = {}, showCamelPrice } = config.value
  let camelPriceStyle = style?.camelPriceStyle || {}
  if (showCamelPrice) {
    camelPriceStyle = {
      before: {
        fontSize: priceFontSize?.value?.digitalSize ? priceFontSize?.value?.digitalSize + 'px' : '13px',
      },
      after: {
        fontSize: priceFontSize?.value?.decimalSize ? priceFontSize?.value?.decimalSize + 'px' : '9px',
      }
    }
  }
  if (!amountWithSymbol) {
    return {}
  }
  const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(salePrice?.amountWithSymbol || '', salePrice?.amount || '')
  return {
    showCamelPrice,
    camelPriceResizeFontsize: config.value?.camelPriceResizeFontsize,
    originString: amountWithSymbol,
    camelPriceStyle,
    currencySymbolPrefix,
    numberBeforeDot,
    numberAfterDot,
    currencySymbolSuffix
  }
})

// 折扣标签
const unitDiscountString = computed(() => {
  const { showDiscountLabelAfterPrice } = config.value ?? {}
  if (showDiscountLabelAfterPrice && Number(goodsInfo.value?.unit_discount || 0) > 0) {
    let _unitDiscountString = `${lang !== 'il' ? '-' : ''}${goodsInfo.value?.unit_discount}%`
    if (GB_cssRight) {
      _unitDiscountString = _unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
    }
    return _unitDiscountString
  }
  return ''
})

// 合规价与原价处理
const priceSuggested = computed(() => {
  const { retailPrice, suggestedSalePriceInfo, retailSuggestPrice } = goodsInfo.value
  const priceRight = {
    show: false,
    price: {},
    labelText: '',
  }
  const priceBottom = {
    show: false,
    price: {},
    labelText: '',
    flipText: false
  }
  if (!config.value.showSuggestedSalePrice) {
    return {
      priceRight,
      priceBottom
    }
  }
  // 合规价
  const suggestedSalePrice = suggestedSalePriceInfo?.suggestedSalePrice || {}
  // 文案
  const description = suggestedSalePriceInfo?.description
  if (suggestedSaleType.value === apolloSuggestedSaleConfig.NORMAL) {
    // 法国 价格右边显示划线价 不显示labelText
    priceRight.show = Number(suggestedSalePrice?.amount || 0) > 0
    priceRight.price = suggestedSalePrice ?? {}
  } else if (suggestedSaleType.value === apolloSuggestedSaleConfig.SPECIAL) {
    // 波兰 isSpecial 只显示一个划线价 放价格下边
    priceBottom.show = Number(suggestedSalePrice?.amount || 0) > 0
    priceBottom.price = suggestedSalePrice ?? {}
    priceBottom.labelText = description
  } else if (suggestedSaleType.value === apolloSuggestedSaleConfig.SPECIALDE) {
    // 德国 价格右边显示原价  价格下边显示划线价
    priceRight.show = Number(retailSuggestPrice?.amount || 0) > 0
    priceRight.price = retailSuggestPrice ?? {}
    priceBottom.show = Number(suggestedSalePrice?.amount || 0) > 0
    priceBottom.price = suggestedSalePrice ?? {}
    priceBottom.labelText = description
    priceBottom.flipText = true
  } else if (config.value.showRetailPrice) {
    // 显示原价
    priceRight.show = Number(retailPrice?.amount || 0) > 0
    priceRight.price = retailPrice ?? {}
  }
  return {
    priceRight,
    priceBottom
  }
})

const isPromotionFun = () => {
  let isPromotionSalePrice = Number(goodsInfo.value?.unit_discount || 0) > 0// 以折扣为准，展示促销色。
  let isOrigin = goodsInfo.value?.isPromotionPrice === 1 || goodsInfo.value?.estimatedPriceInfo?.isSatisfied == 1 || isPromotionSalePrice
  if (suggestedSaleType.value ) { // 合规价
    // 合规时，判断折扣是否为0，返回0时，表示有很小的折扣，但是需要展示促销色。无折扣时返回为空或者null
    isPromotionSalePrice = isPromotionSalePrice || goodsInfo.value?.unit_discount === 0 || goodsInfo.value?.unit_discount === '0'
    if (suggestedSaleType.value === apolloSuggestedSaleConfig.SPECIALDE) {
      // 德国 价格右边显示原价
      const show = Number(goodsInfo.value?.suggestedSalePriceInfo?.suggestedSalePrice?.amount || goodsInfo.value?.retailSuggestPrice?.amount || goodsInfo.value?.retail_suggest_price?.amount || 0) > 0
      isOrigin = isPromotionSalePrice && show // 有30天最低价且有折扣
    } else {
      isOrigin = isPromotionSalePrice && Number(goodsInfo.value?.suggestedSalePriceInfo?.suggestedSalePrice?.amount || 0) > 0 // 有30天最低价且有折扣
    }
  }
  return isOrigin
}

const containerClassName = computed(() => {
  let finalPriceClass = ''
  const isOrigin = isPromotionFun()
  const priceClass = {
    // 会员色
    'brown': goodsInfo.value?.isPaidPrice === 1,
    // 促销色
    'origin': isOrigin,
    // s3会员色
    'darkGold': false, // s3会员价，后续接s3会员用
  }
  finalPriceClass = Object.keys(priceClass).find((key) => {
    return !!priceClass[key]
  })
  return [
    finalPriceClass
  ]
})

// 手动修改salePriceStyle价格样式
const salePriceStyle = computed(() => {
  return config.value?.salePriceStyle || {}
})
</script>

<style lang="less" scoped>
.bff-price-container {
  display: flex;
  flex-direction: column;
  color: var(--font-color, #333);
  padding: 2px 0;
  &.brown{
    --font-color: @sui_color_club_rosegold_dark1;
    --discount-label-color: @sui_color_club_rosegold_dark1;
  }
  &.origin{
    --font-color: @sui_color_discount;
    --discount-label-color: @sui_color_discount;
  }
  &.brown .prices-info__sale-price {
    color: @sui_color_club_rosegold_dark1;
  }
  &.origin .prices-info__sale-price {
    color: @sui_color_discount;
  }
  &.darkGold {
    --font-color: #806208;
    --discount-label-color: #806208;
  }
  // 折扣标签
  .discount-label {
    margin-left: .107rem;
    padding: 0 0.0533rem;
    align-self: center;
    height: 0.3733rem;
    line-height: 0.3467rem;
    direction: ltr /* rtl: ignore */;
    text-align: center;
    border: 1px solid var(--discount-label-color, @sui_color_discount);
    border-radius: 2px;
    color: @sui_color_discount;
    unicode-bidi: plaintext; // ar 站 rtl 只针对 ar 语言，一旦 英文与阿拉伯文混排，new 标签会加入英文的方向，导致显示问题
  }
  .bff-price-container__top {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    max-width: 100%;
    height: 0.5333rem;
    flex-wrap: wrap;
    overflow: hidden; // 折扣标签换行后隐藏
  }
  :deep(.product-card__no-camel-case-price) {
    flex: 0 1 fit-content;
  }
}
</style>
