import { getCurrentInstance, computed } from 'vue'
import  { storeModuleName } from 'public/src/pages/product_app/store/modules/store_pages/index.js'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

export const useMapState = (stateKeys, moduleName = storeModuleName) => {
  const store = useStore()
  return Object.fromEntries(
    stateKeys.map(key => [
      key,
      computed(() => store.state[moduleName][key])
    ])
  )
}

export const useGlobalMapState = (stateKeys) => {
  const store = useStore()
  return Object.fromEntries(
    stateKeys.map(key => [
      key,
      computed(() => store.state[key])
    ])
  )
}

export const useMapGetters = (getterKeys, moduleName = storeModuleName) => {
  const store = useStore()
  return Object.fromEntries(
    getterKeys.map(getter => [
      getter,
      computed(() => store.getters[`${moduleName}/${getter}`])
    ])
  )
}

export const useMapMutations = (mutationKeys, moduleName = storeModuleName) => {
  const store = useStore()
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store.commit(`${moduleName}/${mutation}`, value)
    ])
  )
}

export const useMapActions = (actionKeys, moduleName = storeModuleName) => {
  const store = useStore()
  return Object.fromEntries(
    actionKeys.map(action => [
      action,
      value => store.dispatch(`${moduleName}/${action}`, value)
    ])
  )
}

